import { React, useEffect } from './imports';
import banner from '../Website/CopySource/images/sponsors/sponsor_img.jpg'
import nine from './CopySource/Patners/Ninestars_ok.png'
import through from './CopySource/Patners/ThougthClan-1.png'
import StartUpNews from './CopySource/Patners/StartupNews.png'
import mindsAhead from './CopySource/Patners/MindsAhead-1.png'
import sapient from './CopySource/Patners/Sapient.png'
import { useState } from 'react';
import PopupForm from './PopupForm';

const Sponsors = () => {

  const [isPopupVisible, setPopupVisibility] = useState(false);

  const openPopup = () => {
    setPopupVisibility(true);
  };

  const closePopup = () => {
    setPopupVisibility(false);
  };
  return (
<>
  {/* ts sponsors start*/}
  <section
    className="ts-intro-sponsors"
    style={{ backgroundImage:`url(${banner})`  }}
  >
    <div className="container">
      <div className="row">
        <div className="col-lg-12">
          <h2 className="section-title white">
            <span>Meet This Year’s</span>
            Sponsors and Partners
          </h2>
          {/* section title end*/}
        </div>
        {/* col end*/}
      </div>
      {/* row end*/}
      <div className="row">
        <div className="col-lg-12">
          <div className="sponsors-logo text-center">
            <div className='d-flex flex-wrap justify-center'>
            {/* <a href="">
              <img src={nine} alt="" />
            </a> */}
            <div className="outcome-img home_sponser_logo">
                <a href="#" className="sponsors-logo flex justify-content-center">
                  <img className="img-fluid" src={nine} alt="" />
                </a>
              </div>
            <a href="" className='home_sponser_logo'>
              <img src={through} alt="" />
            </a>
            <a href="" className='sponsers-logo home_sponser_logo'>
              <img src={StartUpNews} alt="" />
            </a>
            <a href="" className='home_sponser_logo'>
              <img src={sapient} alt=""/>
            </a>
             <a href="" className='home_sponser_logo'>
              <img src={mindsAhead} alt="" className='w-25 center' />
            </a>
            
            {/*<a href="">
              <img src="../Website/CopySource/images/sponsors/sponsor6.png" alt="" />
            </a>
            <a href="">
              <img src="../Website/CopySource/images/sponsors/sponsor7.png" alt="" />
            </a>
            <a href="">
              <img src="../Website/CopySource/images/sponsors/sponsor8.png" alt="" />
            </a>
            <a href="">
              <img src="../Website/CopySource/images/sponsors/sponsor9.png" alt="" />
            </a> */}
            </div>
            {/* <div className="sponsor-btn text-center">
              <a href="#" className="btn">
              Enquire Now
              </a>
            </div> */}
            <div>
                    <button className="btn nav-item" onClick={openPopup}>Enquire Now</button>

                    {isPopupVisible && <PopupForm onClose={closePopup} />}
                  </div>
          </div>
          {/* sponsors logo end*/}
        </div>
        {/* col end*/}
      </div>
      {/* row end*/}
    </div>
    {/* container end*/}
  </section>
  {/* ts sponsors end*/}
</>

    )
}

export default Sponsors