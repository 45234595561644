import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import SignupPage from './pages/Signup';
import LoginPage from './pages/Login';
import Dashboard from './pages/Dashboard';
import { Home } from './pages/Home';
import { Speaker } from './pages/Speaker';
import { Partner } from './pages/Partner';
import { Agenda } from './pages/Agenda';
import { Disclaimer } from './pages/Disclaimer';
import { CookiesPolicyPage } from './pages/CookiesPolicyPage';
import "./App.css"
import './components/font.css'
import Whyksa from "./pages/Whyksa";
import './CustomCss/Custom.css'
import { Workshop } from "./pages/Workshop";
import Prospectia from "./pages/Prospectia";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import TawkMessengerReact from '@tawk.to/tawk-messenger-react';


function App() {
  return (
    <div className="min-h-full h-screen  justify-center ">
    <div className="">
     <BrowserRouter>
        <Routes>
            <Route path="/" element={<Home/>} />
            <Route path="/Speaker" element={<Speaker/>} />
            <Route path="/Whyksa" element={<Whyksa/>} />
            <Route path="/Partner" element={<Partner/>} />
            <Route path="/Agenda" element={<Agenda/>} />
            <Route path="/Disclaimer" element={<Disclaimer/>} />
            <Route path="/CookiesPolicyPage" element={<CookiesPolicyPage/>} />
            <Route path="/privacypolicy" element={<PrivacyPolicy/>} />
            
            <Route path="/login" element={<LoginPage/>} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/signup" element={<SignupPage />} />
            <Route path="/workshop" element={<Workshop />} />
            <Route path="/prospectia" element={<Prospectia />} />
        </Routes>
      </BrowserRouter>
        <ToastContainer />
        <TawkMessengerReact
                propertyId="65f43f929131ed19d97a3310"
                widgetId="1hp11gm7h"/>
    </div>
  </div>
  );
}

export default App;
//kush commit