import React from 'react'
import Banner_background from '../CopySource/images/hero_area/banner_bg.jpg'

const Banner_Speaker = () => {
  return (
    <>
  <div
    id="page-banner-area"
    className="page-banner-area"
    style={{ backgroundImage: `url(${Banner_background})` }}
  >
    {/* Subpage title start */}
    <div className="page-banner-title">
      <div className="text-center for_speaker_heading">
        <h2>Speakers and Advisory Board</h2>
        <ol className="breadcrumb">
          <li>
            <a href="#">Reboot /</a>
          </li>
          <li>Speakers</li>
        </ol>
      </div>
    </div>
    {/* Subpage title end */}
  </div>
  {/* Page Banner end */}
</>

  )
}

export default Banner_Speaker