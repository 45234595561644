// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* FormExtra.css */

.form-extra-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.form-extra-left {
  display: flex;
  align-items: center;
}

.remember-checkbox {
  height: 1rem;
  width: 1rem;
  color: purple;
  focus: ring-purple-500;
  border: 1px solid #ccc;
  border-radius: 0.25rem;
}

.remember-label {
  margin-left: 0.5rem;
  font-size: 0.875rem;
  color: #333;
}

.form-extra-right {
  font-size: 0.875rem;
}

.forgot-password-link {
  font-weight: 500;
  color: purple;
}

.forgot-password-link:hover {
  color: #805ad5;
}
`, "",{"version":3,"sources":["webpack://./src/components/FormExtra.css"],"names":[],"mappings":"AAAA,kBAAkB;;AAElB;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,YAAY;EACZ,WAAW;EACX,aAAa;EACb,sBAAsB;EACtB,sBAAsB;EACtB,sBAAsB;AACxB;;AAEA;EACE,mBAAmB;EACnB,mBAAmB;EACnB,WAAW;AACb;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;EAChB,aAAa;AACf;;AAEA;EACE,cAAc;AAChB","sourcesContent":["/* FormExtra.css */\n\n.form-extra-container {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n}\n\n.form-extra-left {\n  display: flex;\n  align-items: center;\n}\n\n.remember-checkbox {\n  height: 1rem;\n  width: 1rem;\n  color: purple;\n  focus: ring-purple-500;\n  border: 1px solid #ccc;\n  border-radius: 0.25rem;\n}\n\n.remember-label {\n  margin-left: 0.5rem;\n  font-size: 0.875rem;\n  color: #333;\n}\n\n.form-extra-right {\n  font-size: 0.875rem;\n}\n\n.forgot-password-link {\n  font-weight: 500;\n  color: purple;\n}\n\n.forgot-password-link:hover {\n  color: #805ad5;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
