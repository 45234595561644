import { useState } from "react";
import Header from "../components/Header";
import PageI from "../components/PageI";
import PageII from "../components/PageII";
import Pagination from "../components/Pagination";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export default function SignupPage() {


  const navigate = useNavigate();
  useEffect(() => {
    let token = localStorage.getItem('token');
    if (token !== null && token !== undefined) {
      navigate('/dashboard', { replace: true });
    }
  }, [navigate]);

  const [Page, setPage] = useState(1);
  const [Page1Data, setPage1Data] = useState({
    MemberEmail: null,
    MemberPassword: null,
    MemberRole: null,
    companyName: null,
    industryType: null,
    personName: null,
    contactNumber: null,
  });

  const [Page2Data, setPage2Data] = useState({
    position: null,
    QuestionaireResponse: {
      question1: "what is your name",
      ans1: null,
      question2: "industryType",
      ans2: null,
      question3: "your hobbies",
      ans3: null,
      question4: "name some places ?",
      ans4: null,
      question5: "xyz",
      ans5: null,
    },
  });

  const handleSubmit = async () => {
    console.log("SubmitEvent");
  };

  return (
    <div style={{ marginTop: "5rem", height:"120vh" }}>
      <Header
        heading="Signup to create an account"
        paragraph="Already have an account? "
        linkName="Login"
        linkUrl="/"
      />
      {Page === 1 && <PageI setPage1Data={setPage1Data} Page1Data={Page1Data} />}
      {Page1Data.MemberRole !== "Delegates" && (
        <button
          style={{
            border: "2px solid #ccc",
            padding: "0.5rem 1rem",
            borderRadius: "0.375rem",
            marginBottom: "1rem",
          }}
          onClick={handleSubmit}
        >
          Submit
        </button>
      )} 
      {Page1Data.MemberRole === "Delegates" && Page === 2 && (
        <PageII Page2Data={Page2Data} setPage2Data={setPage2Data} />
      )}
      {Page1Data.MemberRole === "Delegates" && (
        <Pagination Page={Page} setPage={setPage} Page1Data={Page1Data} Page2Data={Page2Data} />
      )}
    </div>
  );
}
