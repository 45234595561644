import Enquire from "./Enquire";
import { React, useEffect } from "./imports";
import { useState } from "react";

const Blog = () => {
  const [isPopupVisible, setPopupVisibility] = useState(false);

  const openPopup = () => {
    setPopupVisibility(true);
  };

  const closePopup = () => {
    setPopupVisibility(false);
  };
  return (
    <>
      {/* ts blog start*/}
      <section className="ts-blog section-bg" id="Reserv_y_s">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h2 className="section-title mmb175">
                {/* <span>Info Update</span> */}
                RESERVE YOUR SPACE
              </h2>
            </div>
            {/* col end*/}
          </div>
          {/* row end*/}
          <div className="row">
            <div
              className="col-lg-4 wow fadeInUp"
              data-wow-duration="1.5s"
              data-wow-delay="400ms"
            >
              <div className="post">
                <div className="post-media post-image">
                  <a href="#">
                    <img
                      src="images/blog/blog1.jpg"
                      className="img-fluid"
                      alt=""
                    />
                  </a>
                </div>
                <div className="post-body position-relative">
                  <h2 className="text-center">Gold Sponsor </h2>
                  <div className="post-meta">
                    {/* <span className="post-author">
                  <a href="#"></a>
                </span> */}
                    {/* <div className="post-meta-date">3 X 3 SQM SPACE</div> */}
                  </div>
                  <div className="entry-header">
                    {/* <h2 className="entry-title">
                  <a href="#">6 x 3 Exhibiting Space</a>
                </h2> */}
                  </div>
                  <div className="entry-header">
                    <h2 className="entry-title">
                      {/* <a href="#">$6499</a> */}
                    </h2>
                  </div>
                  {/* header end */}
                  <div className="entry-content">
                    <p>6 x 3 Exhibiting Space</p>
                    <p>1 Prospectia Meeting Table</p>
                    <p>4 Attendee Passes</p>
                    <p>15 Min Speaking Slot</p>
                    <p>Networking Foyer Branding</p>
                    <p>Backdrop Branding</p>
                    <p>Welcome Kit Branding</p>
                    <p>Delegate Take Away Bag Inserts</p>
                    <p>2 Page, A4 Event Brochure</p>
                    <p>Print and Digital Branding</p>
                    <p>Prospectia Demand Gen - 3 Months Subscription</p>
                  </div>
                  <div className="post-footer view_all_button  bottom_enqiry_btn">
                    <button className="btn-link text-white" onClick={openPopup}>
                      Enquire Benefits & Pricing
                      <i className="icon icon-arrow-right" />
                    </button>

                    {isPopupVisible && <Enquire onClose={closePopup} />}
                  </div>
                </div>
                {/* post-body end */}
              </div>
              {/* post end*/}
            </div>
            {/* col end*/}
            <div
              className="col-lg-4 wow fadeInUp"
              data-wow-duration="1.5s"
              data-wow-delay="500ms"
            >
              <div className="post">
                <div className="post-media post-image">
                  <a href="#">
                    <img
                      src="images/blog/blog1.jpg"
                      className="img-fluid"
                      alt=""
                    />
                  </a>
                </div>
                <div className="post-body position-relative">
                  <h2 className="text-center">Silver Sponsor </h2>
                  <div className="post-meta">
                    {/* <span className="post-author">
                  <a href="#"></a>
                </span> */}
                    {/* <div className="post-meta-date">3 X 3 SQM SPACE</div> */}
                  </div>
                  <div className="entry-header">
                    {/* <h2 className="entry-title">
                  <a href="#">3 x 3 Exhibiting Space</a>
                </h2> */}
                  </div>
                  <div className="entry-header">
                    <h2 className="entry-title">
                      {/* <a href="#">$8999</a> */}
                    </h2>
                  </div>
                  {/* header end */}
                  <div className="entry-content">
                    <p>3 x 3 Exhibiting Space</p>
                    <p>3 Attendee Passes</p>
                    <p> Networking Foyer Branding</p>
                    <p>Backdrop Branding</p>
                    <p> Welcome Kit Branding</p>
                    <p> Delegate Take Away Bag Inserts</p>
                    <p>1 Page, A4 Event Brochure</p>
                    <p>Print and Digital Branding</p>
                  </div>
                  <div className="post-footer view_all_button bottom_enqiry_btn">
                    {/* <a href="news-single.html" className="btn-link">
                Enquire Benefits & Pricing <i className="icon icon-arrow-right" />
                </a> */}
                    <button className="btn-link text-white" onClick={openPopup}>
                      Enquire Benefits & Pricing
                      <i className="icon icon-arrow-right" />
                    </button>

                    {isPopupVisible && <Enquire onClose={closePopup} />}
                  </div>
                </div>
                {/* post-body end */}
              </div>
              {/* post end*/}
            </div>
            {/* col end*/}
            <div
              className="col-lg-4 wow fadeInUp"
              data-wow-duration="1.5s"
              data-wow-delay="600ms"
            >
              <div className="post">
                <div className="post-media post-image">
                  <a href="#">
                    <img
                      src="images/blog/blog1.jpg"
                      className="img-fluid"
                      alt=""
                    />
                  </a>
                </div>
                <div className="post-body position-relative">
                  <h2 className="text-center">Exhibiting Partner</h2>
                  <div className="post-meta">
                    {/* <span className="post-author">
                  <a href="#"></a>
                </span> */}
                    {/* <div className="post-meta-date">3 X 3 SQM SPACE</div> */}
                  </div>
                  <div className="entry-header">
                    {/* <h2 className="entry-title">
                  <a href="#">3 x 3 Exhibiting Space</a>
                </h2> */}
                  </div>
                  <div className="entry-header">
                    <h2 className="entry-title">
                      {/* <a href="#">$12999</a> */}
                    </h2>
                  </div>
                  {/* header end */}
                  <div className="entry-content">
                    <p>3 x 3 Exhibiting Space</p>
                    <p>2 Attendee Passes</p>
                    <p>Networking Foyer Branding</p>
                    <p>Print and Digital Branding</p>
                  </div>
                  <div className="post-footer view_all_button bottom_enqiry_btn">
                    <button className="btn-link text-white" onClick={openPopup}>
                      Enquire Benefits & Pricing
                      <i className="icon icon-arrow-right" />
                    </button>

                    {isPopupVisible && <Enquire onClose={closePopup} />}
                  </div>
                </div>
                {/* post-body end */}
              </div>
              {/* post end*/}
            </div>
            {/* col end*/}
          </div>
          {/* row end*/}
        </div>
        {/* container end*/}
        {/* shap image*/}
        <div className="speaker-shap">
          <img className="shap2" src="images/shap/news_memphis2.png" alt="" />
          <img className="shap1" src="images/shap/news_memphis1.png" alt="" />
        </div>
      </section>
      {/* ts blog end*/}
    </>
  );
};

export default Blog;
