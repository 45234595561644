import { useEffect, useState } from 'react'
import Admindashboard from '../components/Admindashboard'
import Delegatedash from '../components/Delegatedash'
import Speakerdash  from '../components/Speakerdash'
import Sponserdash  from '../components/Sponserdash'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'

export default function Dashboard() {

  const navigate=useNavigate();
  const [a,setA]=useState(null)
  useEffect(() => {
    const checkAuthentication = async () => {
      const token = localStorage.getItem('token');
      if(!token){
        navigate('/')
      }
      if (token) {
        try {
          const response = await check(token);
          setA(response.data.role);
        } catch (error) {
          if (error.response && error.response.status === 401) {
            console.log('Token Expired User not authenticated. Redirect to login page.');
            localStorage.removeItem('token');
            navigate('/')
          } else {
            console.error('Error checking authentication:', error);
          }
        }
      }
    };

    checkAuthentication();
  }, [navigate]);

  const check = async (token) => {
    const response = await axios.get('http://195.35.22.195:3001/auth', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  };
  return (
    <div className="  w-[100vw] h-[100vh]">
      {a === "Admin" && <Admindashboard />}
      {a === "Delegate" && <Delegatedash />}
      {a === "Speaker" && <Speakerdash />}
      {a === "Sponsor" && <Sponserdash />}
            
    </div>
  );
}
