import React from 'react'
import './Content.css'
const Content = () => {
  return (
    <div className="Terms_container">
    <header>
      <h1>Privacy Policy</h1>
    </header>
    <div className="content">
        <p>THESE TERMS AND CONDITIONS OUTLINE THE RULES AND REGULATIONS FOR THE USE OF OUR WEBSITE. PLEASE ENSURE TO THOROUGHLY REVIEW THIS PRIVACY STATEMENT TO UNDERSTAND OUR PRIVACY POLICY BEFORE SUBMITTING ANY OF YOUR PERSONAL AND BUSINESS INFORMATION.</p>
        <p>By accessing this website, we assume you accept these terms and conditions outlined in this privacy statement, including the collection and use of your information when you register, make a booking, or inquiry. If you do not agree to all the terms and conditions stated on this page, please refrain from using Nowledge Global.</p>
        <p>What Kind of Information We Collect</p>
      <section>
        <h3>Personal Information</h3>
        <p>By accessing this website, we assume you accept these terms and conditions outlined in this privacy statement, including the collection and use of your information when you register, make a booking, or inquiry. If you do not agree to all the terms and conditions stated on this page, please refrain from using Nowledge Global.</p>
      </section>
      <section>
        <h3>Log</h3>
        <p>Our servers automatically collect and record information that the browser on your computer, mobile phone, tablet, or similar device sends whenever you visit our site (a “Device“), including Internet protocol (IP) addresses, browser type and language, referral and exit pages, URLs, and language information. We do not link this automatically-collected data to other information we collect about you. Additionally, we may collect information about various aspects of your site usage or your participation in any Activity, such as login dates and times, viewed content, visited pages, searches, registrations, and clickstream data. Log data will be stored by our servers based on the information sent from your app or browser. This information may be recorded by cookies or other tracking technologies. Please review our cookies policy page for further details.</p>
      </section>
      <section>
        <h3>Location</h3>
        <p>We may collect information about your location through location information accessible through your Device. You can change the privacy settings of your Device at any time to turn off the functionality of sharing location information.</p>
      </section>
      <section>
        <h3>Analytics and Remarketing</h3>
        <p>Nowledge Global utilizes Google Analytics, HubSpot Analytics, Full Story, and other tools to improve our services. Upon your visit, we may also share anonymous information with third-party service providers of analytics services.</p>
      </section>
      <section>
        <h3>Social Media Features</h3>
        <p>Our websites also include social media features like the Twitter ‘Like’ button and the ‘Share’ button that operate on our Service. These features may collect your Internet protocol address, the page you are browsing on the Websites, and may set a cookie enabler feature to function properly. Social media features and widgets are either hosted by a third party or directly on our Service. Your activity regarding these features is governed by the privacy statement of the companies that provide them.</p>
      </section>
        <h2 className='text-black underline mb-5 mt-5'>Usage of Your Information</h2>
      <section>
        <h3>Personal and Business Information</h3>
        <p>We may use your personal and business information for the following general purposes</p>
      </section>
      <section>
        <h3>Communication</h3>
        <p>We use your information to communicate with you, respond to your inquiries, register attendees, issue tickets, share information with partners you have given consent to share information with, and provide you with relevant connections and content during Nowledge Global’s events.</p>
      </section>
      <section>
        <h3>Marketing</h3>
        <p>We use your information for marketing and advertising services of Nowledge Global’s events. To maintain better customer relationships, we measure interest and provide better requested services. To enhance user experience, we may use your information to provide targeted offers and updates about our events.</p>
      </section>
      <section>
        <h3>For legal obligations</h3>
        <p>Nowledge Global may use your information or share your information to fulfill our legal obligation under law or any such requirement that pertains to claims handling, debt collection, and legal processes.</p>
        <p>Improvement of our services: Data analysis, audits, researching, and developing or improving new activities.</p>
      </section>
      <section>
        <h3>Non-Personal Information</h3>
        <p>We may also use aggregated, non-personally identifiable data to analyze user behavior to help improve Nowledge Global’s services. Such non-personal information will be used to monitor the website, analyze trends, and enhance visitor experience.</p>
      </section>
      <section>
        <h3>Data Retention</h3>
        <p>We will retain your personal information for the required duration to fulfill the purposes set out in this Privacy Policy and for the purpose of complying with our legal and regulatory obligations to resolve disputes or/and to enforce our terms of service.</p>
      </section>
      <h2 className='text-black underline mb-5 mt-5'>Your Information is Protected</h2>
      <section>
        <h3>Information Sharing</h3>
        <p>We will not share your personal and business information collected from your use of the Site with others, except as described in this Privacy Policy.</p>
        <p>We may share your personal and business information under the following circumstances:</p>
        <p>In the course of operating our business affiliates & service providers, contractors, and select vendors so that we can operate our business.</p>
        <p>In the event of promotional activities, we will publish any short quote/statement from you or your professional or company bios, award nominations submitted by you in the registration form - that we can use in an upcoming press release announcing the event or on our social media platforms (In less than 100 words).</p>
        <p>With your consent, we may share any presentation that you have provided with the event attendees.</p>
        <p>For the purpose of operations and planning of our company’s strategy, we shall use information about your company budgets, delegate, sponsor, and attendee booking information, affiliate forms, project briefs, or any financial information. The same shall apply to pitch submission information.</p>
        <p>With your consent, we may share any such document, material, or content that you have voluntarily made public. Since such information can be accessed by others, the use of such publicly hosted content by third parties is beyond our control. Should you want us to remove such information from the forums owned by Nowledge Global, you could request removal by mailing us at hello@nowledgeglobal.com</p>
        <p>Subpoenas, court orders, or legal process, to enforce legal rights, to defend against legal claims or in such circumstances where, in our judgment, a disclosure is necessary or appropriate in accordance with applicable law.</p>
        <p>We may share your personal information if we believe it is necessary to investigate, detect, prevent, or otherwise take action regarding illegal activities, suspected fraud, or abuse.</p>
        <p>Should you have any queries regarding this Privacy Policy, feel free to contact us at hello@nowledgeglobal.com.</p>
      </section>
    </div>
  </div>
 
  )
}

export default Content