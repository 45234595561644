import { React, useEffect, useState } from 'react';
import BannerImage from './CopySource/images/hero_area/banner7.jpg';
import axios from 'axios';
import { toast } from 'react-toastify';


const Banner = () => {

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [Ticket, setTicket] = useState('');


  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await axios.post('https://reboot-summit.com:3002/sendemail', { name, email, phone, Ticket });
      console.log(res);
        toast("You Will Be Contact Shortly")
    } catch (error) {
      console.error('Error submitting form:', error);
    }

  };




    return (
        <section className="hero-area hero-speakers">
            <div className="banner-item overlay" style={{ backgroundImage: `url(${BannerImage})` }}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-7">
                            <div className="banner-content-wrap">
                                <h1 className="banner-title wow fadeInUp accelerating mobil_view_accelerating" data-wow-duration="1.5s" data-wow-delay="700ms" id='font_acumin'  >Accelerating</h1>
                                <h1 className="banner-title wow fadeInUp font_arabia mobil_view_arabia" data-wow-duration="1.5s" data-wow-delay="700ms " id='fontArabia'>Arabia's</h1>
                                <h1 className="banner-title wow fadeInUp digitalReality mobil_view_digitalContent" data-wow-duration="1.5s" data-wow-delay="700ms">Digital Reality</h1>
                                <div className="banner-btn wow fadeInUp " data-wow-duration="1.5s" data-wow-delay="800ms">
                                    <a href="#Tickets_Price" className="btn">Buy Ticket</a>
                                    <a href="#Reserv_y_s" className="btn fill">REGISTER TO EXHIBIT</a>
                                </div>
                            </div>
                            {/* Banner content wrap end */}
                        </div>{/* col end*/}
                        <div className="col-lg-4 offset-lg-1">
                            <div className="hero-form-content border_top_change">
                                <h2>Register Now</h2>
                                <p>
                                   #HitTheButton
                                </p>
                                <form action="#" onSubmit={handleSubmit} className="hero-form">
                                    <input className="form-control form-control-name" placeholder="Name" name="name" id="f-name" type="text" required value={name} onChange={(e) => setName(e.target.value)} />
                                    <input className="form-control form-control-phone" placeholder="Phone" name="phone" id="f-phone" type="tel" required value={phone} onChange={(e) => setPhone(e.target.value)}/>
                                    <input className="form-control form-control-email" placeholder="Email" name="email" id="f-email" type="email" required value={email} onChange={(e) => setEmail(e.target.value)}/>
                                    <input className="form-control form-control-email" placeholder="No of Tickets" name="ticket" id="ticket" type="number" required value={Ticket} onChange={(e) => setTicket(e.target.value)}/>
                                    <button className="btn" type="submit"> Register Now</button>
                                </form>{/* form end*/}
                            </div>{/* hero content end*/}
                        </div>{/* col end*/}
                    </div>{/* row end*/}
                </div>
                {/* Container end */}
            </div>
        </section>
    );
}

export default Banner;

