import { React, useEffect } from './imports';

const MapDirection = () => {
  return (
    <>
  {/* ts map direction start*/}
  <section
    className="ts-map-direction wow fadeInUp"
    data-wow-duration="1.5s"
    data-wow-delay="400ms"
  >
    <div className="container">
      <div className="row">
        <div className="col-lg-5 flex align-items-center">
          <h2 className="column-title blue">
            {/* <span className='blue'>Reach us</span> */}
            Program Venue

          </h2>
          {/* <div className="ts-map-tabs">
            <ul className="nav" role="tablist">
              <li className="nav-item">
                <a
                  className="nav-link active"
                  href="#profile"
                  role="tab"
                  data-toggle="tab"
                >
                  Venue
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  href="#buzz"
                  role="tab"
                  data-toggle="tab"
                >
                  Time
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  href="#references"
                  role="tab"
                  data-toggle="tab"
                >
                  How to get there
                </a>
              </li>
            </ul>
            <div className="tab-content direction-tabs">
              <div role="tabpanel" className="tab-pane active" id="profile">
                <div className="direction-tabs-content">
                  <h3>Brighton Waterfront Hotel, Brighton, London</h3>
                  <p className="derecttion-vanue">
                    1Hd- 50, 010 Avenue, NY 90001
                    <br />
                    United States
                  </p>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="contact-info-box">
                        <h3>Tickets info </h3>
                        <p>
                          <strong>Name:</strong> Ronaldo König
                        </p>
                        <p>
                          <strong>Phone:</strong> 009-215-5595
                        </p>
                        <p>
                          <strong>Email: </strong> info@example.com
                        </p>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="contact-info-box">
                        <h3>Programme Details </h3>
                        <p>
                          <strong>Name:</strong> Ronaldo König
                        </p>
                        <p>
                          <strong>Phone:</strong> 009-215-5595
                        </p>
                        <p>
                          <strong>Email: </strong> info@example.com
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div role="tabpanel" className="tab-pane fade" id="buzz">
                <div className="direction-tabs-content">
                  <h3>Brighton Waterfront Hotel, Brighton, London</h3>
                  <p className="derecttion-vanue">
                    1Hd- 50, 010 Avenue, NY 90001
                    <br />
                    United States
                  </p>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="contact-info-box">
                        <h3>Tickets info </h3>
                        <p>
                          <strong>Name:</strong> Ronaldo König
                        </p>
                        <p>
                          <strong>Phone:</strong> 009-215-5595
                        </p>
                        <p>
                          <strong>Email: </strong> info@example.com
                        </p>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="contact-info-box">
                        <h3>Programme Details </h3>
                        <p>
                          <strong>Name:</strong> Ronaldo König
                        </p>
                        <p>
                          <strong>Phone:</strong> 009-215-5595
                        </p>
                        <p>
                          <strong>Email: </strong> info@example.com
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div role="tabpanel" className="tab-pane fade" id="references">
                <div className="direction-tabs-content">
                  <h3>Brighton Waterfront Hotel, Brighton, London</h3>
                  <p className="derecttion-vanue">
                    1Hd- 50, 010 Avenue, NY 90001
                    <br />
                    United States
                  </p>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="contact-info-box">
                        <h3>Tickets info </h3>
                        <p>
                          <strong>Name:</strong> Ronaldo König
                        </p>
                        <p>
                          <strong>Phone:</strong> 009-215-5595
                        </p>
                        <p>
                          <strong>Email: </strong> info@example.com
                        </p>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="contact-info-box">
                        <h3>Programme Details </h3>
                        <p>
                          <strong>Name:</strong> Ronaldo König
                        </p>
                        <p>
                          <strong>Phone:</strong> 009-215-5595
                        </p>
                        <p>
                          <strong>Email: </strong> info@example.com
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </div>
        {/* col end*/}
        <div className="col-lg-6 offset-lg-1">
          <div className="ts-map">
            <div className="mapouter">
              <div className="gmap_canvas">
                <iframe width="50%" height="50%" src="https://maps.google.com/maps?q=+Riyadh%2C+KSA&t=&z=17&ie=UTF8&iwloc=&output=embed" />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* col end*/}
    </div>
    {/* container end*/}
    <div className="speaker-shap">
      <img
        className="shap1 wow fadeInLeft"
        data-wow-duration="1.5s"
        data-wow-delay="300ms"
        src="images/shap/Direction_memphis3.png"
        alt=""
      />
      <img
        className="shap2 wow fadeInRight"
        data-wow-duration="1.5s"
        data-wow-delay="400ms"
        src="images/shap/Direction_memphis2.png"
        alt=""
      />
      <img
        className="shap3 wow fadeInLeft"
        data-wow-duration="1.5s"
        data-wow-delay="500ms"
        src="images/shap/Direction_memphis4.png"
        alt=""
      />
      <img
        className="shap4 wow fadeInUp"
        data-wow-duration="1.5s"
        data-wow-delay="600ms"
        src="images/shap/Direction_memphis1.png"
        alt=""
      />
    </div>
  </section>
  {/* ts map direction end*/}
</>

  )
}

export default MapDirection