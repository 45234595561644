import React from 'react'
import Header from '../Website/Header';
import Workshopcont from '../Website/Workshop/Workshopcont';
import Footer from '../Website/Footer';
export const Workshop = () => {
    
  return (
   <>
    <Header/>
    <Workshopcont/>
    <Footer/>
   </>
  )
}
