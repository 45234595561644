// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ts-venue-feature .single-venue-content i {
  font-size: 72px;
  color: #fff; }

.ts-venue-feature .single-venue-content .ts-venue-title {
  font-size: 30px;
  color: #fff; }
`, "",{"version":3,"sources":["webpack://./src/Website/CopySource/css/shuvo.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,WAAW,EAAE;;AAEf;EACE,eAAe;EACf,WAAW,EAAE","sourcesContent":[".ts-venue-feature .single-venue-content i {\n  font-size: 72px;\n  color: #fff; }\n\n.ts-venue-feature .single-venue-content .ts-venue-title {\n  font-size: 30px;\n  color: #fff; }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
