import React from 'react';
import './FormExtra.css'; // Import the CSS file

export default function FormExtra() {
  return (
    <div className="form-extra-container">
      <div className="flex items-center justify-between">
        <div className="flex items-center">
          <input
            id="remember-me"
            name="remember-me"
            type="checkbox"
            className="remember-checkbox"
          />
          <label htmlFor="remember-me" className="remember-label">
            Remember me
          </label>
        </div>

        <div className="forgot-password">
          <a href="#" className="forgot-password-link">
            Forgot your password?
          </a>
        </div>
      </div>
    </div>
  );
}
