import Header from '../Website/Header';
import Banner from '../Website/Banner';
import Intro from '../Website/Intro';
import FunFact from '../Website/FunFact';
import Speakers from '../Website/Speakers';
import Experience from '../Website/Experience';
import Schedule from '../Website/Schedule';
import Pricing from '../Website/Pricing';
import Blog from '../Website/Blog';
import Sponsors from '../Website/Sponsors';
import MapDirection from '../Website/MapDirection';
import Footer from '../Website/Footer';
import T_and_C from '../Website/Disclaimer/T_and_C';
import Disclaimer_Banner from '../Website/Disclaimer/Disclaimer_Banner';
import { useEffect } from 'react';
import { useRef } from 'react';



export const Disclaimer = () => {
  const topRef = useRef(null);

  useEffect(() => {
    topRef.current.scrollIntoView({ behavior: 'smooth' });
  }, []);
  return (
    <>
    <div ref={topRef}></div>
        <Header/>
        <Disclaimer_Banner/>
        <T_and_C/>
        <Footer/>
    </>
  )
}
