// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* PageII.css */

.page-container {
    height: 70vh;
  }
  
  .form-container {
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  
  .fixed-input {
    border-radius: 0.375rem;
    appearance: none;
    position: relative;
    display: block;
    width: 100%;
    padding: 0.75rem;
    border: 1px solid #ccc;
    placeholder: #ccc;
    color: #333;
    outline: none;
    transition: border-color 0.3s;
  }
  
  .fixed-input:focus {
    border-color: purple;
    ring: purple-500;
    focus: z-10;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/PageII.css"],"names":[],"mappings":"AAAA,eAAe;;AAEf;IACI,YAAY;EACd;;EAEA;IACE,gBAAgB;IAChB,aAAa;IACb,sBAAsB;IACtB,SAAS;EACX;;EAEA;IACE,uBAAuB;IACvB,gBAAgB;IAChB,kBAAkB;IAClB,cAAc;IACd,WAAW;IACX,gBAAgB;IAChB,sBAAsB;IACtB,iBAAiB;IACjB,WAAW;IACX,aAAa;IACb,6BAA6B;EAC/B;;EAEA;IACE,oBAAoB;IACpB,gBAAgB;IAChB,WAAW;EACb","sourcesContent":["/* PageII.css */\n\n.page-container {\n    height: 70vh;\n  }\n  \n  .form-container {\n    margin-top: 2rem;\n    display: flex;\n    flex-direction: column;\n    gap: 1rem;\n  }\n  \n  .fixed-input {\n    border-radius: 0.375rem;\n    appearance: none;\n    position: relative;\n    display: block;\n    width: 100%;\n    padding: 0.75rem;\n    border: 1px solid #ccc;\n    placeholder: #ccc;\n    color: #333;\n    outline: none;\n    transition: border-color 0.3s;\n  }\n  \n  .fixed-input:focus {\n    border-color: purple;\n    ring: purple-500;\n    focus: z-10;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
