import Header from '../Website/Header';
import Footer from '../Website/Footer';
import Banner_Speaker from '../Website/Speaker_Page/Banner_Speaker';
import Key_Speakers from '../Website/Speaker_Page/Key_Speakers';
import Event_Speakers from '../Website/Speaker_Page/Event_Speakers';
import { useRef } from 'react';
import { useEffect } from 'react';


export const Speaker = () => {
  const topRef = useRef(null);

  useEffect(() => {
    topRef.current.scrollIntoView({ behavior: 'smooth' });
  }, []);
  return (
    <>
    <div ref={topRef}></div>
        <Header/>
        <Banner_Speaker/>
        <Key_Speakers/>
        <Event_Speakers/>
        <Footer/>
    </>
  )
}
