import React from 'react';
import './Page2.css';
import image1 from '../CopySource/images/prospectia/1.png';
import image2 from '../CopySource/images/prospectia/2.png';
import image4 from '../CopySource/images/prospectia/4.png';
import image6 from '../CopySource/images/prospectia/6.png';
import image5 from '../CopySource/images/prospectia/5.png';

const Prospectia_page2 = () => {
  return (
    <div className="page2_main_container">
    <div className="image1">
        <img src={image1} alt="Precision Targeting" />
      </div>
      <div className="image2">
        <img src={image2} alt="Tailored Solutions" />
      </div>
      <div className="image3">
        <img src={image4} alt="Enhanced Efficiency" />
      </div>
      <div className="image4">
        <img src={image6} alt="Measurable Results" />
      </div>
      <div className="page2_heading">
        <h1>ABOUT <br /> PROSPECTIA</h1>
        <p>Prospectia is Nowledge Global’s white glove demand generation offering for businesses seeking to generate demand for their product or service. Prospectio is designed to help you abuse the power of precise, targeted outreach.</p>
      </div>

      <div className="page2_image_container">
        <img src={image5} alt="Prospectia Image" />
      </div>

      <div className="page2_sub_heading">
        <h2>Simplifying the Sales Rocket Science</h2>
      </div>

      <div className="page2_content">
        <div className="page2_left">
          <p>Our entire range of activity is centered around one primary motive - to help you close as many deals as humanly possible</p>
        </div>
        <div className="page2_right">
          <p>And until the day we can deploy AI and ML models to help you do this better, we promise to continue adding value as an extension of your sales team</p>
        </div>
      </div>
    </div>
  );
};

export default Prospectia_page2;
