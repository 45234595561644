// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* App.css */

.Terms_container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .Terms_container header {
    text-align: center;
    margin-bottom: 30px;
  }
  
  .Terms_container h1 {
    color: #333;
    font-size: 36px;
  }
  
  .Terms_container h2,
  .Terms_container h3 {
    color: #555;
    margin-top: 20px;
  }
  
  .Terms_container .content {
    margin-top: 20px;
  }
  
  .Terms_container section {
    padding: 20px;
    background-color: #fff;
    margin-bottom: 20px;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .Terms_container p {
    line-height: 1.6;
    color: #666;
  }
  `, "",{"version":3,"sources":["webpack://./src/Website/Disclaimer/T_and_C.css"],"names":[],"mappings":"AAAA,YAAY;;AAEZ;IACI,gBAAgB;IAChB,cAAc;IACd,aAAa;IACb,yBAAyB;IACzB,mBAAmB;IACnB,uCAAuC;EACzC;;EAEA;IACE,kBAAkB;IAClB,mBAAmB;EACrB;;EAEA;IACE,WAAW;IACX,eAAe;EACjB;;EAEA;;IAEE,WAAW;IACX,gBAAgB;EAClB;;EAEA;IACE,gBAAgB;EAClB;;EAEA;IACE,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,kBAAkB;IAClB,wCAAwC;EAC1C;;EAEA;IACE,gBAAgB;IAChB,WAAW;EACb","sourcesContent":["/* App.css */\n\n.Terms_container {\n    max-width: 800px;\n    margin: 0 auto;\n    padding: 20px;\n    background-color: #f9f9f9;\n    border-radius: 10px;\n    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);\n  }\n  \n  .Terms_container header {\n    text-align: center;\n    margin-bottom: 30px;\n  }\n  \n  .Terms_container h1 {\n    color: #333;\n    font-size: 36px;\n  }\n  \n  .Terms_container h2,\n  .Terms_container h3 {\n    color: #555;\n    margin-top: 20px;\n  }\n  \n  .Terms_container .content {\n    margin-top: 20px;\n  }\n  \n  .Terms_container section {\n    padding: 20px;\n    background-color: #fff;\n    margin-bottom: 20px;\n    border-radius: 5px;\n    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);\n  }\n  \n  .Terms_container p {\n    line-height: 1.6;\n    color: #666;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
