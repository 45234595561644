import React from 'react';
import './Page1.css'; // Import the CSS file

function PageI(props) {
  const handleChange = (event) => {
    props.setPage1Data({
      ...props.Page1Data,
      [event.target.name]: event.target.value,
    });
    console.log(props.Page1Data);
  };

  return (
    <div className='page-container'>
      <form className="form-container">
        <input
          value={props.Page1Data.MemberEmail}
          type="email"
          className="fixed-input"
          placeholder='Email Address'
          name='MemberEmail'
          onChange={handleChange}
          onBlur={handleChange}
        />
        <input
          value={props.Page1Data.MemberPassword}
          type="password"
          className="fixed-input"
          placeholder='Password'
          name='MemberPassword'
          onChange={handleChange}
          onBlur={handleChange}
        />
        <input
          value={props.Page1Data.MemberRole}
          type="text"
          className="fixed-input"
          placeholder='Role'
          name='MemberRole'
          onChange={handleChange}
        />
        <input
          type="text"
          className="fixed-input"
          placeholder='companyName'
          name='companyName'
          onChange={handleChange}
        />
        <input
          type="text"
          className="fixed-input"
          placeholder='industryType'
          name='industryType'
          onChange={handleChange}
        />
        <input
          type="text"
          className="fixed-input"
          placeholder='personName'
          name='personName'
          onChange={handleChange}
        />
        <input
          type="text"
          className="fixed-input"
          placeholder='Contact Number'
          name='contactNumber'
          onChange={handleChange}
        />
      </form>
    </div>
  );
}

export default PageI;
