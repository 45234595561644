import Header from "../components/Header"
import Login from "../components/Login"
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export default function LoginPage(){
    const navigate = useNavigate();

  useEffect(() => {
    let token = localStorage.getItem('token');
    if (token !== null && token !== undefined) {
      navigate('/dashboard', { replace: true });
    }
  }, [navigate]);
    return(
        <div className="mt-[10vh] w-[40vw]">
             <Header
                heading="Login to your account"
                paragraph="Don't have an account yet? "
                linkName="Signup"
                linkUrl="/signup"
                />
            <Login/>
        </div>
    )
}