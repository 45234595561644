import React from 'react';
import './Page3.css';
import image1 from '../CopySource/images/prospectia/1.png';
import image2 from '../CopySource/images/prospectia/2.png';
import image4 from '../CopySource/images/prospectia/4.png';
import image11 from '../CopySource/images/prospectia/11.png';
import image7 from '../CopySource/images/prospectia/7.png';
import image8 from '../CopySource/images/prospectia/8.png';
import image9 from '../CopySource/images/prospectia/9.png';
import image10 from '../CopySource/images/prospectia/10.png';

const Prospectia_page3 = () => {
  return (
    <div className="main_container">
      <div className="image1">
        <img src={image1} alt="Precision Targeting" />
      </div>
      <div className="image2">
        <img src={image2} alt="Tailored Solutions" />
      </div>
      <div className="image3">
        <img src={image4} alt="Enhanced Efficiency" />
      </div>
      <div className="image4">
        <img src={image11} alt="Measurable Results" />
      </div>

      <div className="heading">
        <h1>Close Better,<br/>Faster.</h1>
      </div>

      <div className="content">
        <div className="left">
          <h3>Precision Targeting</h3>
          <p>Pinpoint your ideal customers with laser-like accuracy, ensuring every outreach resonates with maximum impact.</p>
        </div>

        <div className="right">
          <img src={image7} alt="Precision Targeting" />
        </div>
      </div>

      <div className="content reverse">
        <div className="left">
          <h3>Tailored Solutions</h3>
          <p>From market analysis to customer pre-qualification; to captive boardroom meetings, each service offering is meticulously tailored to align with your business objectives and unique cases.</p>
        </div>
        <div className="right">
          <img src={image8} alt="Tailored Solutions" />
        </div>
      </div>
      <div className="content">
        <div className="left">
          <h3>Enhanced Efficiency</h3>
          <p>Streamline your demand generation efforts with our expert guidance, maximizing ROI while minimizing resource expenditure.</p>
        </div>
        <div className="right">
          <img src={image9} alt="Enhanced Efficiency" />
        </div>
      </div>

      <div className="content reverse">
        <div className="left">
          <h3>Measurable Results</h3>
          <p>Track and measure the success of your demand generation campaigns with granular precision, enabling data-driven decision-making for continuous improvement.</p>
        </div>
        <div className="right">
          <img src={image10} alt="Measurable Results" />
        </div>
      </div>
    </div>
  );
};

export default Prospectia_page3;
