import React from "react";
import nine from "../CopySource/Patners/Ninestars_ok.png";
import through from "../CopySource/Patners/ThougthClan-1.png";
import StartUpNews from "../CopySource/Patners/StartupNews.png";
import TechnicalPartners from "../../components/TechnicalPartners";
import { useState } from "react";
import PopupForm from "../PopupForm";

const Our_Sponsors = () => {

  const [isPopupVisible, setPopupVisibility] = useState(false);

  const openPopup = () => {
    setPopupVisibility(true);
  };

  const closePopup = () => {
    setPopupVisibility(false);
  };



  return (
    <>
      <section id="ts-sponsors" className="ts-sponsors">
        <div className="container">
          <div className="row text-center">
            <div className="col-lg-12 mx-auto">
              <h2 className="section-title text-center ">
                <span>Say Hello To 2024</span>
                Patners and Sponsors
              </h2>
            </div>
          </div>
          {/*/ Title row end */}
          <div className="sponsors-wrap">
            <h3
              className="sponsor-title text-center sponser_headings_in_partner"
              id="font_barlow"
            >
              Gold Sponsors
            </h3>
            <div className=" sponsor-padding text-center">
              <div className="">
                <a href="#" className="sponsors-logo logo_margin">
                  <img className="img-fluid" src={nine} alt="" />
                </a>
              </div>
              {/* Col 1 end */}
              {/* <div className="col-lg-3">
            <a href="#" className="sponsors-logo">
              <img
                className="img-fluid"
                src="images/sponsors/sponsor-2.png"
                alt=""
              />
            </a>
          </div> */}
              {/* Col 2 end */}
              {/* <div className="col-lg-3">
            <a href="#" className="sponsors-logo">
              <img
                className="img-fluid"
                src="images/sponsors/sponsor-3.png"
                alt=""
              />
            </a>
          </div> */}
              {/* Col 3 end */}
              {/* <div className="col-lg-3">
            <a href="#" className="sponsors-logo">
              <img
                className="img-fluid"
                src="images/sponsors/sponsor-4.png"
                alt=""
              />
            </a>
          </div> */}
              {/* Col 3 end */}
            </div>
          </div>
          {/*/ Content row 1 end */}
          <div className="sponsors-wrap">
            <h3
              className="sponsor-title text-center sponser_headings_in_partner"
              id="font_barlow"
            >
              Silver Sponsors
            </h3>
            <div className=" sponsor-padding text-center">
              <div className="">
                <a href="#" className="sponsors-logo logo_margin">
                  <img className="img-fluid" src={through} alt="" />
                </a>
              </div>
              {/* Col 1 end */}
              {/* <div className="col-lg-3">
            <a href="#" className="sponsors-logo">
              <img
                className="img-fluid"
                src="images/sponsors/sponsor-6.png"
                alt=""
              />
            </a>
          </div> */}
              {/* Col 2 end */}
              {/* <div className="col-lg-3">
            <a href="#" className="sponsors-logo">
              <img
                className="img-fluid"
                src="images/sponsors/sponsor-7.png"
                alt=""
              />
            </a>
          </div> */}
              {/* Col 3 end */}
              {/* <div className="col-lg-3">
            <a href="#" className="sponsors-logo">
              <img
                className="img-fluid"
                src="images/sponsors/sponsor-8.png"
                alt=""
              />
            </a>
          </div> */}
              {/* Col 3 end */}
            </div>
          </div>
          {/*/ Content row 2 end */}
          <div className="sponsors-wrap">
            <h3
              className="sponsor-title text-center sponser_headings_in_partner"
              id="font_barlow"
            >
              Media Partners
            </h3>
            <div className=" sponsor-padding text-center">
              <div className="">
                <a href="#" className="sponsors-logo logo_margin">
                  <img className="img-fluid" src={StartUpNews} alt="" />
                </a>
              </div>
              {/* Col 1 end */}
              {/* <div className="col-lg-3">
            <a href="#" className="sponsors-logo">
              <img
                className="img-fluid"
                src="images/sponsors/sponsor-2.png"
                alt=""
              />
            </a>
          </div> */}
              {/* Col 2 end */}
              {/* <div className="col-lg-3">
            <a href="#" className="sponsors-logo">
              <img
                className="img-fluid"
                src="images/sponsors/sponsor-3.png"
                alt=""
              />
            </a>
          </div> */}
              {/* Col 3 end */}
              {/* <div className="col-lg-3">
            <a href="#" className="sponsors-logo">
              <img
                className="img-fluid"
                src="images/sponsors/sponsor-4.png"
                alt=""
              />
            </a>
          </div> */}
              {/* Col 3 end */}
            </div>
          </div>
          {/*/ Content row 3 end */}

          {/* 3section */}

          <TechnicalPartners />
          {/* 3section */}
          <div className="row">
            <div className="col-lg-12 mx-auto">
              {/* <div className="general-btn text-center">
            <a className="btn" href="#">
              Become A Sponsor
            </a>
          </div> */}

              <div className="text-center">
                <button className="btn nav-item" onClick={openPopup}>
                Become A Sponsor
                </button>

                {isPopupVisible && <PopupForm onClose={closePopup} />}
              </div>
            </div>
          </div>
          {/*/ Content row 3 end */}
        </div>
        {/*/ Container end */}
      </section>
      {/* Sponsors end */}
    </>
  );
};

export default Our_Sponsors;
