import React from 'react'
import Banner_background from '../CopySource/images/hero_area/banner_bg.jpg'

const Banner_KSA = () => {
  return (
    <>
  <div
    id="page-banner-area"
    className="page-banner-area"
    style={{ backgroundImage: `url(${Banner_background})` }}
  >
    {/* Subpage title start */}
    <div className="page-banner-title">
      <div className="text-center">
        <h2 className='lh-1'>WHY REBOOT KSA</h2>
        <ol className="breadcrumb">
          <li>
            <a href="#">The significance of KSA in accelerating the Middle East's digital reality<br/> #RebootSummitArabia</a>
          </li>
        </ol>
      </div>
    </div>
    {/* Subpage title end */}
  </div>
  {/* Page Banner end */}
</>

  )
}

export default Banner_KSA