import React from 'react';
import './PageII.css'; // Import the CSS file

function PageII(props) {
  const handleChange = (event) => {
    const { name, value } = event.target;
    const [field, key] = name.split('.');

    props.setPage2Data(prevState => ({
      ...prevState,
      [field]: {
        ...prevState[field],
        [key]: value,
      },
    }));
    console.log(props.Page2Data);
  };

  const handleChangePosition = (event) => {
    props.setPage2Data({
      ...props.Page2Data,
      [event.target.name]: event.target.value,
    });
  };

  return (
    <div className='page-container'>
      <form className="form-container">
        <input
          type="text"
          className="fixed-input"
          placeholder='position'
          name='position'
          onChange={handleChangePosition}
        />
        <input
          type="text"
          className="fixed-input"
          placeholder='what is your name'
          name='QuestionaireResponse.ans1'
          onChange={handleChange}
        />
        <input
          type="text"
          className="fixed-input"
          placeholder='industryType'
          name='QuestionaireResponse.ans2'
          onChange={handleChange}
        />
        <input
          type="text"
          className="fixed-input"
          placeholder='your hobbies'
          name='QuestionaireResponse.ans3'
          onChange={handleChange}
        />
        <input
          type="text"
          className="fixed-input"
          placeholder='name some places'
          name='QuestionaireResponse.ans4'
          onChange={handleChange}
        />
        <input
          type="text"
          className="fixed-input"
          placeholder='xyz'
          name='QuestionaireResponse.ans5'
          onChange={handleChange}
        />
      </form>
    </div>
  );
}

export default PageII;
