import { React } from "./imports";
const EventOutcome = () => {
  return (
    <section className="ts-event-outcome event-intro">
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <div className="">
              <div className="outcome-content ts-exp-content">
                <h2 className="column-title green font_style_none" id="font_acumin" >
                  {/* <span>Event Outcomes</span> */}
                  About Reboot
                </h2>
                <p>
                Reboot Summit Arabia aims to catalyze the Kingdom’s rapidly accelerating digital reality landscape in alignment with Saudi Arabia's ambitious Vision 2030.  This two day, cross industry, technology summit is scheduled as part of the Riyadh Technology Week will host pre-qualified, invite-only attendees who are leading technologists, futurists and the most impactful names in tech striving to change the way we live, study, work. 
                </p>
                <p>
                This 2 day closed door premier summit, located in the heart of Riyadh, is designed to engage, inspire and impact over 400 VIP participants, 75+ Global Product and service Providers. It comes at a time when Saudi Arabia is actively diversifying the economy, improving the quality of life for citizens and positioning the kingdom in an elite league of AI enabled economies.
                </p>
                <a href="#ts-speakers" className="btn">
                  Learn More
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="outcome-content">
              <div className="outcome-img">
              <iframe
                width="100%"
                height="462.85"
                src="https://www.youtube.com/embed/qHeb9XUJ7Bg?si=eKY8g0a25S_Psx4x?autoplay=1"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              ></iframe>

                
                
              </div>
              {/* <h3 className="img-title text-white"><a href="#" className="text-white">Learn Things</a></h3> */}
            </div>
          </div>
          {/* <div className="col-lg-4">
                        <div className="outcome-content">
                            <div className="outcome-img overlay">
                                <img className="" src={About_pic2} alt="" />
                            </div>
                            <h3 className="img-title"><a href="#" className="text-white">Connect People</a></h3>
                        </div>
                    </div> */}
        </div>
      </div>
    </section>
  );
};

export default EventOutcome;
