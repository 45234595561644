import React from 'react'
import Privacy_Banner from '../Website/PrivacyPolicy/Privacy_Banner'
import Header from '../Website/Header';
import Content from '../Website/PrivacyPolicy/Content';
import Footer from '../Website/Footer';
import { useEffect } from 'react';
import { useRef } from 'react';

const PrivacyPolicy = () => {
  const topRef = useRef(null);

  useEffect(() => {
    topRef.current.scrollIntoView({ behavior: 'smooth' });
  }, []);
  return (
    <>
    <div ref={topRef}></div>
        <Header/>
        <Privacy_Banner/>
        <Content/>
        <Footer/>
    </>
  )
}

export default PrivacyPolicy