import Header from '../Website/Header';
import Banner from '../Website/Banner';
import Intro from '../Website/Intro';
import FunFact from '../Website/FunFact';
import Speakers from '../Website/Speakers';
import Experience from '../Website/Experience';
import Schedule from '../Website/Schedule';
import Pricing from '../Website/Pricing';
import Blog from '../Website/Blog';
import Sponsors from '../Website/Sponsors';
import MapDirection from '../Website/MapDirection';
import Footer from '../Website/Footer';
import Banner_Speaker from '../Website/Speaker_Page/Banner_Speaker';
import Key_Speakers from '../Website/Speaker_Page/Key_Speakers';
import Event_Speakers from '../Website/Speaker_Page/Event_Speakers';
import Partner_Banner from '../Website/Partner_Page/Partner_Banner';
import Our_Sponsors from '../Website/Partner_Page/Our_Sponsors';
import Agenda_Banner from '../Website/Agenda/Agenda_Banner';
import First_day from '../Website/Agenda/First_day';
import Second_day from '../Website/Agenda/Second_day';
import Third from '../Website/Agenda/Third';
import { useEffect } from 'react';
import { useRef } from 'react';






export const Agenda = () => {
  const topRef = useRef(null);

  useEffect(() => {
    topRef.current.scrollIntoView({ behavior: 'smooth' });
  }, []);
  return (
    <>
    <div ref={topRef}></div>
        <Header/>
        <Agenda_Banner/>
        <First_day/>
        <Second_day/>
        {/* <Third/> */}
        <Footer/>
    </>
  )
}