import { React, useEffect } from "./imports";
import ConferenceHall from "./CopySource/images/book_seat_img.jpg";
import { Link } from "react-router-dom";
const Footer = () => {
  return (
    <>
      {/* ts footer area start*/}
      <div className="footer-area">
        {/* ts-book-seat start*/}
        <section
          className="ts-book-seat"
          style={{ backgroundImage: `url(${ConferenceHall}) `}}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-8 mx-auto">
                <div className="book-seat-content text-center mb-100">
                  <h2 className="section-title white">
                    {/* <span>Hurry up!</span> */}
                    #HitTheButton
                  </h2>
                  {/* <a href="#" className="btn">
                    Register
                  </a> */}
                </div>
                {/* book seat end*/}
              </div>
              {/* col end*/}
            </div>
            {/* row end*/}
            <div className="ts-footer-newsletter">
              <div
                className="ts-newsletter"
                style={{
                  backgroundImage: "url(./images/shap/subscribe_pattern.png)",
                }}
              >
                <div className="row">
                  <div className="col-lg-6 mx-auto">
                    <div className="ts-newsletter-content">
                      {/* <h2 className="section-title">
                        Some Heading you can give here
                      </h2> */}
                    </div>
                    <div className="newsletter-form">
                      <form
                        action="#"
                        method="post"
                        className="media align-items-end flex-row"
                      >
                        <div className="email-form-group media-body">
                          <input
                            type="email"
                            name="email"
                            id="newsletter-form-email"
                            className="form-control"
                            placeholder="Your Email"
                            autoComplete="off"
                            required=""
                          />
                        </div>
                        <div className="d-flex ts-submit-btn">
                          <button className="btn">Subscribe</button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* container end*/}
        </section>
        {/* book seat  end*/}
        {/* footer start*/}
        <footer className="ts-footer">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 d-flex justify-content-center flex-column">
                {/* New content */}
                <div className="ts-footer-content ">
                  {/* Line One Content */}
                  <p><span id="font_barlow" style={{color:"white"}}>Event Hotline:</span>+44 122 408 5862</p>
                  {/* Sponsorship and Exhibition Enquiries */}
                  <p>
                    <span id="font_barlow" style={{color:"white"}}>Sponsorship and Exhibition Enquiries</span>
                    Roger Jeffery
                    <a href="mailto:sponsorship@nowledgeglobal.com">
                      sponsorship@nowledgeglobal.com
                    </a>
                  </p>
                  {/* Delegate and Workshop Enquiries */}
                  <p>
                  <span id="font_barlow" style={{color:"white"}}>Delegate and Workshop Enquiries</span>
                    Jason Ryker
                    <a href="mailto:Jason@nowledgeglobal.com">
                      Jason@nowledgeglobal.com
                    </a>
                  </p>
                  {/* Line Two Content */}
                  <p>
                  <span id="font_barlow" style={{color:"white"}}>Speakers and Association Relations</span>
                    Jack Samuel <br />
                    <a href="mailto:jack@nowledgeglobal.com">
                      jack@nowledgeglobal.com
                    </a>
                  </p>
                  {/* Media and PR Partnership Relations */}
                  <p>
                  <span id="font_barlow" style={{color:"white"}}>Media and PR Partnership Relations</span>
                    Uzma Khan <br />
                    <a href="mailto:marketing@nowledgeglobal.com">
                      marketing@nowledgeglobal.com
                    </a>
                  </p>
                  {/* Operations and on Ground Support */}
                  <p>
                  <span id="font_barlow" style={{color:"white"}}>Operations and on Ground Support</span>
                    Esther Cherry <br />
                    <a href="mailto:operations@nowledgeglobal.com">
                      operations@nowledgeglobal.com
                    </a>
                  </p>
                </div>
                {/* footer social and menu */}
                <div className="ts-footer-social-menu ">
                  {/* Social links */}
                  <div className="ts-footer-social text-center mb-30">
                    <ul>
                      <li>
                        <a href="#">
                          <i className="fa fa-linkedin" />
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fa fa-twitter" />
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fa fa-instagram" />
                        </a>
                      </li>
                    </ul>
                  </div>
                  {/* footer menu */}
                  {/* <div className="footer-menu text-center mb-25">
                    <ul>
                      <li>
                        <a href="#">About Eventime</a>
                      </li>
                      <li>
                        <a href="#">Blog</a>
                      </li>
                      <li>
                        <a href="#">Contact</a>
                      </li>
                      <li>
                        <a href="#">Tickets</a>
                      </li>
                      <li>
                        <a href="#">Venue</a>
                      </li>
                    </ul>
                  </div> */}
                  <div className="footer-menu text-center mb-25">
                    <ul>
                      <li>
                        <Link to="/privacypolicy">Privacy Policy</Link>
                      </li>
                      <li>
                        <Link to="/CookiesPolicyPage">Cookie Policy</Link>
                      </li>
                      <li>
                        <Link to="/Disclaimer"> General T & C</Link>
                      </li>
                    </ul>
                  </div>
                </div>
                {/* Copyright */}
                <div className="copyright-text text-center ">
                  <p>Copyright ©  2024 Nowledge. All Rights Reserved. The information in this website/document is for promotional purposes only and not contractual.
                  No part of this website/document may be reproduced or transmitted in any form or by any means without the prior written permission of Nowledge Global</p>
                </div>
              </div>
            </div>
          </div>
        </footer>

        {/* footer end*/}
        {/* <div className="BackTo">
          <a href="#" className="fa fa-angle-up" aria-hidden="true" />
        </div> */}
      </div>
      {/* ts footer area end*/}
    </>
  );
};

export default Footer;