import React from 'react'

const Second_day = () => {
  return (
<section className="ts-schedule section-bg">
  <div className="container">
    <div className="row">
      <div className="col-lg-12">
        <h2 className="section-title"> Day Two Agenda </h2>
      </div>
      {/* col end*/}
    </div>
    {/* row end*/}
    <div className="row">
      <div className="col-lg-12">
        <div className="tab-content schedule-tabs">
          <div role="tabpanel" className="tab-pane active" id="date2">
            <div className="schedule-listing">
              <div className="schedule-slot-time">
                <span> 08:00 - 09:00</span>
              </div>
              <div className="schedule-slot-info">
                <a href="#">
                  <img
                    className="schedule-slot-speakers"
                    src="images/speakers/speaker5.jpg"
                    alt=""
                  />
                </a>
                <div className="schedule-slot-info-content">
                  <h3 className="schedule-slot-title">Registrations and Welcome Networking Coffee
                    {/* <strong>@ Johnsson Agaton</strong> */}
                  </h3>
                  <p>

                  </p>
                </div>
                {/*Info content end */}
              </div>
              {/* Slot info end */}
            </div>
            {/*schedule-listing end */}
            <div className="schedule-listing">
              <div className="schedule-slot-time">
                <span> 09:00 - 09:20</span>
              </div>
              <div className="schedule-slot-info">
                <a href="#">
                  <img
                    className="schedule-slot-speakers"
                    src="images/speakers/speaker6.jpg"
                    alt=""
                  />
                </a>
                <div className="schedule-slot-info-content">
                  <h3 className="schedule-slot-title">
                    Welcome Address
                    {/* <strong>@ Fredric Martinsson</strong> */}
                  </h3>
                  <p>

                  </p>
                </div>
                {/*Info content end */}
              </div>
              {/* Slot info end */}
            </div>
            {/*schedule-listing end */}
            <div className="schedule-listing">
              <div className="schedule-slot-time">
                <span>09:20 - 09:40</span>
              </div>
              <div className="schedule-slot-info">
                <a href="#">
                  <img
                    className="schedule-slot-speakers"
                    src="images/speakers/speaker7.jpg"
                    alt=""
                  />
                </a>
                <div className="schedule-slot-info-content">
                  <h3 className="schedule-slot-title">
                  Inaugural Keybote Address - AI: The Big revolution since the evolution into steam engines.
                    {/* <strong>@ Hall Building</strong> */}
                  </h3>
                  <p>
                    This keynote address positions Artificial Intelligence as a revolution on par with the invention of the steam engine. The speaker, will share personal experiences leading digital transformations and offer practical advice on overcoming common challenges and building a culture of innovation.
                  </p>
                </div>
                {/*Info content end */}
              </div>
              {/* Slot info end */}
            </div>
            {/*schedule-listing end */}
            <div className="schedule-listing">
              <div className="schedule-slot-time">
                <span>09:40 - 10:00</span>
              </div>
              <div className="schedule-slot-info">
                <a href="#">
                  <img
                    className="schedule-slot-speakers"
                    src="images/speakers/speaker7.jpg"
                    alt=""
                  />
                </a>
                <div className="schedule-slot-info-content">
                  <h3 className="schedule-slot-title">
                    Available Solution Presentation Slot
                    {/* <strong>@ Hall Building</strong> */}
                  </h3>
                  <p>
                    
                  </p>
                </div>
                {/*Info content end */}
              </div>
              {/* Slot info end */}
            </div>
            {/*schedule-listing end */}
            <div className="schedule-listing">
              <div className="schedule-slot-time">
                <span>10:00 - 10:20</span>
              </div>
              <div className="schedule-slot-info">
                <a href="#">
                  <img
                    className="schedule-slot-speakers"
                    src="images/speakers/speaker7.jpg"
                    alt=""
                  />
                </a>
                <div className="schedule-slot-info-content">
                  <h3 className="schedule-slot-title">
                  Keynote Presentation - Leading the global race in Manufactuing Automation through Digital Adoption
                    {/* <strong>@ Hall Building</strong> */}
                  </h3>
                  <p>
                    "One of Saudi Arabia's vision 2030 ambitions is to become a global leader in manufacturing automation through digital adoption. Let us delve into how emerging technologies like AI, blockchain, and the Internet of Things are transforming the manufacturing landscape and explores the potential impact on society, work, and human behavior."
                  </p>
                </div>
                {/*Info content end */}
              </div>
              {/* Slot info end */}
            </div>
            {/*schedule-listing end */}
            <div className="schedule-listing">
              <div className="schedule-slot-time">
                <span>10:20: - 10:40</span>
              </div>
              <div className="schedule-slot-info">
                <div className="schedule-slot-info-content">
                  <h3 className="schedule-slot-title">
                  Coffee Break
                    {/* <strong>@ Agaton Johnsson</strong> */}
                  </h3>
                  <a href="#">
                    <img src="images/schedule_lunch.png" alt="" />
                  </a>
                </div>
                {/*Info content end */}
              </div>
              {/* Slot info end */}
            </div>
            {/*schedule-listing end */}
            <div className="schedule-listing">
              <div className="schedule-slot-time">
                <span>10:40 - 11:30</span>
              </div>
              <div className="schedule-slot-info">
                <a href="#">
                  <img
                    className="schedule-slot-speakers"
                    src="images/speakers/speaker8.jpg"
                    alt=""
                  />
                </a>
                <div className="schedule-slot-info-content">
                  <h3 className="schedule-slot-title">
                    Fireside Chat - Building Digital Capabilities: Upskilling Your Workforce for the Future
                    {/* <strong>@ Henrikon Rebecca</strong> */}
                  </h3>
                  <p>
                  This fireside chat tackles a critical topic: upskilling the workforce for the digital age. It will explore key areas like identifying in-demand skills and overcoming resistance to change. Balancing this, it will delve into exciting opportunities and showcase inspiring examples of effective upskilling initiatives. The chat aims to share actionable insights and recommendations for individuals, companies, and policymakers on navigating the digital skills gap and building future-proof workforces.
                  </p>
                </div>
                {/*Info content end */}
              </div>
              {/* Slot info end */}
            </div>
            {/*schedule-listing end */}
            <div className="schedule-listing">
              <div className="schedule-slot-time">
                <span>11:30 - 11:45</span>
              </div>
              <div className="schedule-slot-info">
                <a href="#">
                  <img
                    className="schedule-slot-speakers"
                    src="images/speakers/speaker8.jpg"
                    alt=""
                  />
                </a>
                <div className="schedule-slot-info-content">
                  <h3 className="schedule-slot-title">
                    Available Solution Presentation Slot
                    {/* <strong>@ Henrikon Rebecca</strong> */}
                  </h3>
                  <p>
                    
                  </p>
                </div>
                {/*Info content end */}
              </div>
              {/* Slot info end */}
            </div>
            {/*schedule-listing end */}
            <div className="schedule-listing">
              <div className="schedule-slot-time">
                <span>11:45 - 12:05</span>
              </div>
              <div className="schedule-slot-info">
                <a href="#">
                  <img
                    className="schedule-slot-speakers"
                    src="images/speakers/speaker8.jpg"
                    alt=""
                  />
                </a>
                <div className="schedule-slot-info-content">
                  <h3 className="schedule-slot-title">
                  Keynote Presentation - Industry 5.0, Are we there yet? Integrating Human Centricity & Mechanised Efficiency
                    {/* <strong>@ Henrikon Rebecca</strong> */}
                  </h3>
                  <p>
                  "The closing keynote address serves as a culmination of the conference's themes, focusing on the emerging concept of Industry 5.0. It explores how Industry 5.0 envisions a future where humans and machines work collaboratively, with technology augmenting rather than replacing human capabilities. This thought-provoking discussion will leave a lasting impression on the future of work and human-machine collaboration in the next industrial revolution."
                  </p>
                </div>
                {/*Info content end */}
              </div>
              {/* Slot info end */}
            </div>
            {/*schedule-listing end */}
            <div className="schedule-listing">
              <div className="schedule-slot-time">
                <span>12:05 - 13:00</span>
              </div>
              <div className="schedule-slot-info">
                <a href="#">
                  <img
                    className="schedule-slot-speakers"
                    src="images/speakers/speaker8.jpg"
                    alt=""
                  />
                </a>
                <div className="schedule-slot-info-content">
                  <h3 className="schedule-slot-title">
                  Prayer and Gala Networking Luncheon
                    {/* <strong>@ Henrikon Rebecca</strong> */}
                  </h3>
                  <p>

                  </p>
                </div>
                {/*Info content end */}
              </div>
              {/* Slot info end */}
            </div>
            {/*schedule-listing end */}
            <div className="schedule-listing">
              <div className="schedule-slot-time">
                <span>13:00 - 17:00</span>
              </div>
              <div className="schedule-slot-info">
                <a href="#">
                  <img
                    className="schedule-slot-speakers"
                    src="images/speakers/speaker8.jpg"
                    alt=""
                  />
                </a>
                <div className="schedule-slot-info-content">
                  <h3 className="schedule-slot-title">
                    Exclusive Certified Starclass Workshop: Nuts & Bolts of Digitalization by Mr. Henrik Von Scheel
                    {/* <strong>@ Henrikon Rebecca</strong> */}
                  </h3>
                  <p>
                    This exclusive workshop equips you with the essential skills to #plan, #strategize, and #execute powerful digitalization initiatives, regardless of project size.
                  </p>
                </div>
                {/*Info content end */}
              </div>
              {/* Slot info end */}
            </div>
            {/*schedule-listing end */}
            {/* <div className="schedule-listing-btn">
              <a href="#" className="btn">
                More Details
              </a>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  </div>
  {/* container end*/}
</section>

  )
}

export default Second_day