import axios from 'axios';
import React, { useState, useEffect } from 'react';

const CountryDropdown = ({ Country, setCountry }) => {
  const [countries, setCountries] = useState([]);

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await axios.get('https://restcountries.com/v3.1/all');
        const countryNames = response.data.map(country => country.name.common);
        setCountries(countryNames);
      } catch (error) {
        console.error('Error fetching countries:', error);
      }
    };

    fetchCountries();
  }, []);

  const handleCountryChange = event => {
    const selectedCountry = event.target.value;
    setCountry(selectedCountry);
  };

  return (
    <div className="fieldclass input-effect">
      {/* <label htmlFor="country">Choose a country:</label> */}
      <select className= 'effect-16' id="country" name="country" onChange={handleCountryChange} value={Country}>
        <option value="none">Choose a country:</option>
        {countries.map((country, index) => (
          <option key={index} value={country}>
            {country}
          </option>
        ))}
      </select>
    </div>
  );
};

export default CountryDropdown;
