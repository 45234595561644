import { useState } from 'react';
import { loginFields } from "../constants/formFields";
import FormAction from "./FormAction";
import FormExtra from "./FormExtra";
import Input from "./Input";
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const fields=loginFields;
let fieldsState = {};
fields.forEach(field=>fieldsState[field.id]='');

export default function Login(){
    const [loginState,setLoginState]=useState(fieldsState);
    const navigate=useNavigate();

    const handleChange=(e)=>{
        setLoginState({...loginState,[e.target.id]:e.target.value})
    }

    const handleSubmit=(e)=>{
        e.preventDefault();
        authenticateUser();
    }

    const authenticateUser = async() =>{
        try {
            let APIuRL = 'http://195.35.22.195:3001/login';
            const response = await axios.post(APIuRL, { MemberEmail:loginState.email, MemberPassword:loginState.password });
            localStorage.setItem("token",response.data.token);
            localStorage.setItem("role",response.data.user.MemberRole);
            navigate('/dashboard');
          } catch (error) {
            console.error(error.response.data);
          }
    }

    return(
        <form style={{ marginTop: '2rem', display: 'grid', gap: '1rem' }} onSubmit={handleSubmit}>
        <div >
            {
                fields.map(field=>
                        <Input
                            key={field.id}
                            handleChange={handleChange}
                            value={loginState[field.id]}
                            labelText={field.labelText}
                            labelFor={field.labelFor}
                            id={field.id}
                            name={field.name}
                            type={field.type}
                            isRequired={field.isRequired}
                            placeholder={field.placeholder}
                    />
                
                )
            }
        </div>

        <FormExtra/>
        <FormAction handleSubmit={handleSubmit} text="Login"/>

      </form>
    )
}