import React from 'react'

const Third = () => {
  return (
    <section className="ts-schedule section-bg">
  <div className="container">
    <div className="row">
      <div className="col-lg-12">
        <h2 className="section-title"> Day Third Agenda</h2>
      </div>
      {/* col end*/}
    </div>
    {/* row end*/}
    <div className="row">
      <div className="col-lg-12">
        <div className="tab-content schedule-tabs">
          <div role="tabpanel" className="tab-pane active" id="date2">
            <div className="schedule-listing">
              <div className="schedule-slot-time">
                <span> 02.30 - 03.30 PM</span>
                Workshop
              </div>
              <div className="schedule-slot-info">
                <a href="#">
                  <img
                    className="schedule-slot-speakers"
                    src="images/speakers/speaker5.jpg"
                    alt=""
                  />
                </a>
                <div className="schedule-slot-info-content">
                  <h3 className="schedule-slot-title">
                    Marketing Matters!
                    <strong>@ Johnsson Agaton</strong>
                  </h3>
                  <p>
                    How you transform your business as technology, consumer,
                    habits industry dynamics change? Find out from those leading
                    the charge. How you transform{" "}
                  </p>
                </div>
                {/*Info content end */}
              </div>
              {/* Slot info end */}
            </div>
            {/*schedule-listing end */}
            <div className="schedule-listing">
              <div className="schedule-slot-time">
                <span> 03.30 - 04.30 PM</span>
                Workshop
              </div>
              <div className="schedule-slot-info">
                <a href="#">
                  <img
                    className="schedule-slot-speakers"
                    src="images/speakers/speaker6.jpg"
                    alt=""
                  />
                </a>
                <div className="schedule-slot-info-content">
                  <h3 className="schedule-slot-title">
                    Reinventing Experiences
                    <strong>@ Fredric Martinsson</strong>
                  </h3>
                  <p>
                    How you transform your business as technology, consumer,
                    habits industry dynamics change? Find out from those leading
                    the charge. How you transform{" "}
                  </p>
                </div>
                {/*Info content end */}
              </div>
              {/* Slot info end */}
            </div>
            {/*schedule-listing end */}
            <div className="schedule-listing">
              <div className="schedule-slot-time">
                <span> 04.30 - 05.30 PM</span>
                Workshop
              </div>
              <div className="schedule-slot-info">
                <a href="#">
                  <img
                    className="schedule-slot-speakers"
                    src="images/speakers/speaker7.jpg"
                    alt=""
                  />
                </a>
                <div className="schedule-slot-info-content">
                  <h3 className="schedule-slot-title">
                    Cultures of Creativity
                    <strong>@ Hall Building</strong>
                  </h3>
                  <p>
                    How you transform your business as technology, consumer,
                    habits industry dynamics change? Find out from those leading
                    the charge. How you transform{" "}
                  </p>
                </div>
                {/*Info content end */}
              </div>
              {/* Slot info end */}
            </div>
            {/*schedule-listing end */}
            <div className="schedule-listing">
              <div className="schedule-slot-time">
                <span> 05.30 - 06.30 PM</span>
              </div>
              <div className="schedule-slot-info">
                <div className="schedule-slot-info-content">
                  <h3 className="schedule-slot-title">
                    Lunch Break
                    <strong>@ Agaton Johnsson</strong>
                  </h3>
                  <a href="#">
                    <img src="images/schedule_lunch.png" alt="" />
                  </a>
                </div>
                {/*Info content end */}
              </div>
              {/* Slot info end */}
            </div>
            {/*schedule-listing end */}
            <div className="schedule-listing">
              <div className="schedule-slot-time">
                <span> 06.30 - 07.30 PM</span>
                Workshop
              </div>
              <div className="schedule-slot-info">
                <a href="#">
                  <img
                    className="schedule-slot-speakers"
                    src="images/speakers/speaker8.jpg"
                    alt=""
                  />
                </a>
                <div className="schedule-slot-info-content">
                  <h3 className="schedule-slot-title">
                    Human Centered Design
                    <strong>@ Henrikon Rebecca</strong>
                  </h3>
                  <p>
                    How you transform your business as technology, consumer,
                    habits industry dynamics change? Find out from those leading
                    the charge. How you transform{" "}
                  </p>
                </div>
                {/*Info content end */}
              </div>
              {/* Slot info end */}
            </div>
            {/*schedule-listing end */}
            <div className="schedule-listing-btn">
              <a href="#" className="btn">
                More Details
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  {/* container end*/}
</section>
  )
}

export default Third