// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.page1_main_container {
    position: relative;
    max-width: 800px;
    min-height: 1300px;
    margin: 0 auto;
    padding: 20px 20px 180px;
}

.right_aligned_section {
    position: absolute;
    top: 100px;
    right: 0;
    display: flex;
    flex-direction: column;
    align-items: right;
    height: 22%;
    justify-content: space-between;
}

.left_aligned_section {
    position: absolute;
    left: 0;
    top:600px;
}

.logo_image {

}

.contact_info {
    text-align: right;
}

.welcome_text {}

.prospectia_text {}

.excellence_text {}



.page1_main_container .image1 {
    position: absolute;
    top: 0px;
    left: 30px;
}

.page1_main_container .image2 {
    position: absolute;
    top: 450px;
    right: 0;
}

.page1_main_container .image3 {
    position: absolute;
    bottom: 250px;
    left: 100px;
}

.page1_main_container .image4 {
    position: absolute;
    bottom: 50px;
    right: 50px;
}`, "",{"version":3,"sources":["webpack://./src/Website/Prospectia/Page1.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,gBAAgB;IAChB,kBAAkB;IAClB,cAAc;IACd,wBAAwB;AAC5B;;AAEA;IACI,kBAAkB;IAClB,UAAU;IACV,QAAQ;IACR,aAAa;IACb,sBAAsB;IACtB,kBAAkB;IAClB,WAAW;IACX,8BAA8B;AAClC;;AAEA;IACI,kBAAkB;IAClB,OAAO;IACP,SAAS;AACb;;AAEA;;AAEA;;AAEA;IACI,iBAAiB;AACrB;;AAEA,eAAe;;AAEf,kBAAkB;;AAElB,kBAAkB;;;;AAIlB;IACI,kBAAkB;IAClB,QAAQ;IACR,UAAU;AACd;;AAEA;IACI,kBAAkB;IAClB,UAAU;IACV,QAAQ;AACZ;;AAEA;IACI,kBAAkB;IAClB,aAAa;IACb,WAAW;AACf;;AAEA;IACI,kBAAkB;IAClB,YAAY;IACZ,WAAW;AACf","sourcesContent":[".page1_main_container {\n    position: relative;\n    max-width: 800px;\n    min-height: 1300px;\n    margin: 0 auto;\n    padding: 20px 20px 180px;\n}\n\n.right_aligned_section {\n    position: absolute;\n    top: 100px;\n    right: 0;\n    display: flex;\n    flex-direction: column;\n    align-items: right;\n    height: 22%;\n    justify-content: space-between;\n}\n\n.left_aligned_section {\n    position: absolute;\n    left: 0;\n    top:600px;\n}\n\n.logo_image {\n\n}\n\n.contact_info {\n    text-align: right;\n}\n\n.welcome_text {}\n\n.prospectia_text {}\n\n.excellence_text {}\n\n\n\n.page1_main_container .image1 {\n    position: absolute;\n    top: 0px;\n    left: 30px;\n}\n\n.page1_main_container .image2 {\n    position: absolute;\n    top: 450px;\n    right: 0;\n}\n\n.page1_main_container .image3 {\n    position: absolute;\n    bottom: 250px;\n    left: 100px;\n}\n\n.page1_main_container .image4 {\n    position: absolute;\n    bottom: 50px;\n    right: 50px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
