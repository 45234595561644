import { React, useEffect } from "./imports";
import { useState } from "react";
import PopupForm from '../Website/PopupForm'
import { Link } from 'react-router-dom';
import logo from "../Website/CopySource/images/logos/logo.png"

const Header = () => {

    const [isPopupVisible, setPopupVisibility] = useState(false);

  const openPopup = () => {
    setPopupVisibility(true);
  };

  const closePopup = () => {
    setPopupVisibility(false);
  };
  return (
    <header id="header" className="header header-transparent">
      <div className="container">
        <nav className="navbar navbar-expand-lg navbar-light">
          {/* logo*/}
          <Link to="/">
            <img src={logo} alt="Brand" />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarNavDropdown"
            aria-controls="navbarNavDropdown"
            aria-expanded="false"
            aria-label="Toggle navigation"
            style={{backgroundColor:"#05e57a", borderColor:"#05e57a"}}
          >
            <span className="navbar-toggler-icon">
              <i className="icon icon-menu"></i>
            </span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNavDropdown">
            <ul className="navbar-nav ml-auto">
              <li className="dropdown nav-item active">
              <Link to="/Whyksa">
                WHY KSA 
              </Link>
                {/* <ul className="dropdown-menu" role="menu">
                                    <li><a href="index.html">Home One</a></li>
                                    <li><a href="index-2.html">Home Two</a></li>
                                    <li><a href="index-3.html">Home Three</a></li>
                                    <li><a href="index-4.html">Home Four</a></li>
                                    <li><a href="index-5.html">Home Five</a></li>
                                    <li><a href="index-6.html">Home Six</a></li>
                                    <li><a href="index-7.html">Home Seven</a></li>
                                </ul> */}
              </li>

              <li className="nav-item dropdown">
              <Link to="/Speaker">Speakers</Link>
              </li>

              <li className="dropdown nav-item">
              <Link to="/Partner">Partners</Link>
              </li>

              <li className="nav-item dropdown">
              <Link to="/Agenda">Agenda</Link>
              </li>
              <li className="nav-item dropdown">
              <Link to="/prospectia">PROSPECTIA</Link>
              </li>
              <li className="nav-item">
                <Link to="/workshop">Workshop</Link>
              </li>
              {/* <li className="header-ticket nav-item">
                
                  <div>
                    <button className="btn nav-item" onClick={openPopup}>REGISTER</button>

                    {isPopupVisible && <PopupForm onClose={closePopup} />}
                  </div>
                
              </li> */}
            </ul>
              <li className="header-ticket nav-item register_li">
                
                  <div className="text-center">
                    <button className="btn nav-item" onClick={openPopup}>REGISTER</button>

                    {isPopupVisible && <PopupForm onClose={closePopup} />}
                  </div>
                
              </li>
          </div>
        </nav>
      </div>
    </header>
  );
};

export default Header;
