import React from 'react'
import BannerImage from '../CopySource/images/hero_area/banner_bg.jpg'

const Cookies_banner = () => {
  return (
    <>
  <div
    id="page-banner-area"
    className="page-banner-area"
    style={{ backgroundImage: `url(${BannerImage})` }}
  >
    {/* Subpage title start */}
    <div className="page-banner-title">
      <div className="text-center">
        <h2>Cookies Policies</h2>
        {/* <ol className="breadcrumb">
          <li>
            <a href="#">Exhibit /</a>
          </li>
          <li>Schedule List</li>
        </ol> */}
      </div>
    </div>
    {/* Subpage title end */}
  </div>
  {/* Page Banner end */}
</>

  )
}

export default Cookies_banner