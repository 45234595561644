import React from 'react'

const First_day = () => {
  return (
    <section className="ts-schedule">
  <div className="container">
    <div className="row">
      <div className="col-lg-12">
        <h2 className="section-title"> Day One Agenda</h2>
      </div>
      {/* col end*/}
    </div>
    {/* row end*/}
    <div className="row">
      <div className="col-lg-12">
        <div className="tab-content schedule-tabs">
          <div role="tabpanel" className="tab-pane active" id="date3">
            <div className="schedule-listing">
              <div className="schedule-slot-time">
                <span> 08:00 - 09:00</span>
              </div>
              <div className="schedule-slot-info">
                <a href="#">
                  <img
                    className="schedule-slot-speakers"
                    src="images/speakers/speaker1.jpg"
                    alt=""
                  />
                </a>
                <div className="schedule-slot-info-content">
                  <h3 className="schedule-slot-title">
                  Registrations and Welcome Networking Coffee
                    {/* <strong>@ Fredric Martinsson</strong> */}
                  </h3>
                  <p>
                    At the Registration Foyer
                  </p>
                </div>
                {/*Info content end */}
              </div>
              {/* Slot info end */}
            </div>
            {/*schedule-listing end */}
            <div className="schedule-listing">
              <div className="schedule-slot-time">
                <span> 09:00 - 09:20</span>
              </div>
              <div className="schedule-slot-info">
                <a href="#">
                  <img
                    className="schedule-slot-speakers"
                    src="images/speakers/speaker2.jpg"
                    alt=""
                  />
                </a>
                <div className="schedule-slot-info-content">
                  <h3 className="schedule-slot-title">
                    Welcome Address
                    {/* <strong>@ Melisa Lundryn</strong> */}
                  </h3>
                  <p>
                    {/* How you transform your business as technology, consumer,
                    habits industry dynamics change? Find out from those leading
                    the charge. How you transform */}
                  </p>
                </div>
                {/*Info content end */}
              </div>
              {/* Slot info end */}
            </div>
            {/*schedule-listing end */}
            <div className="schedule-listing">
              <div className="schedule-slot-time">
                <span> 09:20 - 09:40</span>
              </div>
              <div className="schedule-slot-info">
                <a href="#">
                  <img
                    className="schedule-slot-speakers"
                    src="images/speakers/speaker3.jpg"
                    alt=""
                  />
                </a>
                <div className="schedule-slot-info-content">
                  <h3 className="schedule-slot-title">
                  Inaugural Keybote Address - Embracing the Digital Age: Saudi Arabia's Vision 2030 for a Thriving Tech Ecosystem
                    {/* <strong>@ Johnsson Agaton</strong> */}
                  </h3>
                  <p>
                    This opening keynote address sets the stage for the conference by outlining Saudi Arabia's ambitious Vision 2030 plan. It explores how technology is crucial for achieving the Vision's goals of economic diversification and highlights the Kingdom's initiatives to foster a strong domestic tech sector.
                  </p>
                </div>
                {/*Info content end */}
              </div>
              {/* Slot info end */}
            </div>
            {/*schedule-listing end */}
            <div className="schedule-listing launce">
              <div className="schedule-slot-time">
                <span> 09:40 - 10:00</span>
              </div>
              <div className="schedule-slot-info">
                <div className="schedule-slot-info-content">
                  <h3 className="schedule-slot-title">
                  Available Solution Presentation Slot
                    {/* <strong>@ Rebecca Henrikon</strong> */}
                  </h3>
                  <a href="#">
                    <img src="images/schedule_lunch.png" alt="" />
                  </a>
                </div>
                {/*Info content end */}
              </div>
              {/* Slot info end */}
            </div>
            {/*schedule-listing end */}
            <div className="schedule-listing">
              <div className="schedule-slot-time">
                <span> 10:00 - 10:20</span>
              </div>
              <div className="schedule-slot-info">
                <a href="#">
                  <img
                    className="schedule-slot-speakers"
                    src="images/speakers/speaker4.jpg"
                    alt=""
                  />
                </a>
                <div className="schedule-slot-info-content">
                  <h3 className="schedule-slot-title">
                  Keynote Presentation - The Digital Wave: Reshaping Industries and Societies
                    {/* <strong>@ Agaton Johnsson</strong> */}
                  </h3>
                  <p>
                    Designed to help you gain valuable insights into the transformative potential of open innovation in shaping the future landscape of business and society, this keynote session explores how open innovation transcends traditional boundaries, fostering collaboration across diverse sectors to drive sustainable growth and innovation.
                  </p>
                </div>
                {/*Info content end */}
              </div>
              {/* Slot info end */}
            </div>
            {/*schedule-listing end */}
            <div className="schedule-listing">
              <div className="schedule-slot-time">
                <span> 10:20: - 10:40</span>
              </div>
              <div className="schedule-slot-info">
                <a href="#">
                  <img
                    className="schedule-slot-speakers"
                    src="images/speakers/speaker4.jpg"
                    alt=""
                  />
                </a>
                <div className="schedule-slot-info-content">
                  <h3 className="schedule-slot-title">
                    Networking Coffee Break
                    {/* <strong>@ Agaton Johnsson</strong> */}
                  </h3>
                  <p>
                    
                  </p>
                </div>
                {/*Info content end */}
              </div>
              {/* Slot info end */}
            </div>
            {/*schedule-listing end */}
            <div className="schedule-listing">
              <div className="schedule-slot-time">
                <span> 10:40 - 11:30</span>
              </div>
              <div className="schedule-slot-info">
                <a href="#">
                  <img
                    className="schedule-slot-speakers"
                    src="images/speakers/speaker4.jpg"
                    alt=""
                  />
                </a>
                <div className="schedule-slot-info-content">
                  <h3 className="schedule-slot-title">
                  Power Panel Discussion - Beyond Silos: Building the Future Through Open Innovation - A Cross Industry Perspective
                    {/* <strong>@ Agaton Johnsson</strong> */}
                  </h3>
                  <p>
                  "Beyond Silos: Building the Future Through Open Innovation - A Cross Industry Perspective"" moderated by a prominent technology journalist. Our panel features diverse voices from government, startups, and established companies. Explore challenges such as siloed structures and resistance to change, alongside opportunities in open innovation ecosystems and cross-sector partnerships. Delve into best practices through insightful case studies showcasing successful collaborations driving digital innovation. Walk away with actionable insights and recommendations for fostering an enabling environment conducive to collaboration across industries."
                  </p>
                </div>
                {/*Info content end */}
              </div>
              {/* Slot info end */}
            </div>
            {/*schedule-listing end */}
            <div className="schedule-listing">
              <div className="schedule-slot-time">
                <span>11:30 - 11:45</span>
              </div>
              <div className="schedule-slot-info">
                <a href="#">
                  <img
                    className="schedule-slot-speakers"
                    src="images/speakers/speaker4.jpg"
                    alt=""
                  />
                </a>
                <div className="schedule-slot-info-content">
                  <h3 className="schedule-slot-title">
                  Available Solution Presentation Slot
                    {/* <strong>@ Agaton Johnsson</strong> */}
                  </h3>
                  <p>
                    
                  </p>
                </div>
                {/*Info content end */}
              </div>
              {/* Slot info end */}
            </div>
            {/*schedule-listing end */}
            <div className="schedule-listing">
              <div className="schedule-slot-time">
                <span> 11:45 - 12:05</span>
              </div>
              <div className="schedule-slot-info">
                <a href="#">
                  <img
                    className="schedule-slot-speakers"
                    src="images/speakers/speaker4.jpg"
                    alt=""
                  />
                </a>
                <div className="schedule-slot-info-content">
                  <h3 className="schedule-slot-title">
                  Keynote Presentation - Securing the Digital Oasis: Threaths, Challenges and Strategies for Critical Infra and Industries
                    {/* <strong>@ Agaton Johnsson</strong> */}
                  </h3>
                  <p>
                  "Diving deep into the critical issue of cybersecurity for Saudi Arabia's booming industrial automation sector. This keynote emphasizes the growing cyber threats targeting critical infrastructure and proposes strategies to secure the Kingdom's digital oasis."
                  </p>
                </div>
                {/*Info content end */}
              </div>
              {/* Slot info end */}
            </div>
            {/*schedule-listing end */}
            <div className="schedule-listing">
              <div className="schedule-slot-time">
                <span> 12:05 - 13:00</span>
              </div>
              <div className="schedule-slot-info">
                <a href="#">
                  <img
                    className="schedule-slot-speakers"
                    src="images/speakers/speaker4.jpg"
                    alt=""
                  />
                </a>
                <div className="schedule-slot-info-content">
                  <h3 className="schedule-slot-title">
                  Prayer and Gala Networking Luncheon
                    {/* <strong>@ Agaton Johnsson</strong> */}
                  </h3>
                  <p>
                    
                  </p>
                </div>
                {/*Info content end */}
              </div>
              {/* Slot info end */}
            </div>
            {/*schedule-listing end */}
            <div className="schedule-listing">
              <div className="schedule-slot-time">
                <span>13:00 - 13:20</span>
              </div>
              <div className="schedule-slot-info">
                <a href="#">
                  <img
                    className="schedule-slot-speakers"
                    src="images/speakers/speaker4.jpg"
                    alt=""
                  />
                </a>
                <div className="schedule-slot-info-content">
                  <h3 className="schedule-slot-title">
                    Featured Case Study: Advancing Personalised Customer Journeys
                    {/* <strong>@ Agaton Johnsson</strong> */}
                  </h3>
                  <p>
                  This session sheds light on how companies deploy innovative solutions in offering personalised omnichannel customer journeys.
                  </p>
                </div>
                {/*Info content end */}
              </div>
              {/* Slot info end */}
            </div>
            {/*schedule-listing end */}
            <div className="schedule-listing">
              <div className="schedule-slot-time">
                <span>13:20 - 14:00</span>
              </div>
              <div className="schedule-slot-info">
                <a href="#">
                  <img
                    className="schedule-slot-speakers"
                    src="images/speakers/speaker4.jpg"
                    alt=""
                  />
                </a>
                <div className="schedule-slot-info-content">
                  <h3 className="schedule-slot-title">
                    Power Panel Discussion -  Data Driven Decision Making with a Human Centric Approach
                    {/* <strong>@ Agaton Johnsson</strong> */}
                  </h3>
                  <p>
                  "This interactive panel discussion brings together industry experts, technology providers, and government representatives to explore how artificial intelligence (AI) and machine learning (ML) are transforming various sectors within the framework of Industry 4.0. Expect insights into how different industries are capitalizing on AI and ML to achieve their digital transformation goals."
                  </p>
                </div>
                {/*Info content end */}
              </div>
              {/* Slot info end */}
            </div>
            {/*schedule-listing end */}
            <div className="schedule-listing">
              <div className="schedule-slot-time">
                <span>14:00 - 16:30</span>
              </div>
              <div className="schedule-slot-info">
                <a href="#">
                  <img
                    className="schedule-slot-speakers"
                    src="images/speakers/speaker4.jpg"
                    alt=""
                  />
                </a>
                <div className="schedule-slot-info-content">
                  <h3 className="schedule-slot-title">
                  Prospectia B2B Meetings
                    {/* <strong>@ Agaton Johnsson</strong> */}
                  </h3>
                  <p>
                    
                  </p>
                </div>
                {/*Info content end */}
              </div>
              {/* Slot info end */}
            </div>
            {/*schedule-listing end */}
            {/* <div className="schedule-listing-btn">
              <a href="#" className="btn">
                More Details
              </a>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  </div>
  {/* container end*/}
  <div className="speaker-shap">
    <img
      className="shap2"
      src="images/shap/home_schedule_memphis1.png"
      alt=""
    />
    <img
      className="shap1"
      src="images/shap/home_schedule_memphis2.png"
      alt=""
    />
  </div>
</section>

  )
}

export default First_day