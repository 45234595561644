import React from 'react'
import './T_and_C.css'
const T_and_C = () => {
  return (
    <div className="Terms_container">
    <header>
      <h1>Terms and Conditions</h1>
    </header>
    <div className="content">
      <section>
        <h2>General Terms and Conditions</h2>
        <p>The following terminology pertains to these Terms and Conditions, Privacy Statement, and Disclaimer Notice, as well as all Agreements: "Client", "You", and "Your" denote you, the individual visiting this website, and are in accordance with Nowledge’s terms and conditions...</p>
      </section>
      <section>
        <h3>ALTERATION OF THE ADVERTISED PACKAGE</h3>
        <p>Nowledge shall make every effort to adhere to the advertised package and agenda for the event disclosed to the Client before the date of this agreement...</p>
      </section>
      <section>
        <h3>CANCELLATION BY CLIENT</h3>
        <p>Any cancellation request received within 48 hours of booking will entitle the Client to receive a credit note equivalent to the full booking amount...</p>
        <p>Any cancellation request raised after 48 hours of booking will entitle the Client to receive a credit note equivalent to 50% of the total booking amount, provided the booking date is not less than 30 days from the event date...</p>
        <p>Any cancellation request received within 30 days from the event date will not be entitled to any credit note...</p>
        <p>If the client does not officially request a cancellation and does not participate in the event, no credit note will be issued...</p>
        <p>In all the above scenarios, if the client has not yet made the payment, 100% of the invoiced amount shall remain due...</p>
      </section>
      <section>
        <h3>LIMITATION OF LIABILITY</h3>
        <p>Nowledge shall not be liable for any special, consequential, or incidental damages arising from this agreement or the services provided hereunder...</p>
      </section>
      <section id='privacy_policy'>
        <h3>PRIVACY AND DATA PROTECTION</h3>
        <p>Nowledge is granted the right to use any material recorded at the Event, for promotional purposes, in photographic, audio, or audio-visual format...</p>
      </section>
      <section>
        <h3>FORCE MAJEURE</h3>
        <p>In the event of postponement or abandonment of the event due to war, threats or acts of terrorism, or force majeure, Nowledge shall not be liable for any expenditure, liability, or loss incurred by Clients...</p>
        <p>If the rescheduled event takes place more than six months from the original date, the client may demand a full refund, which will be processed within 30 days of receiving the request...</p>
      </section>
      <section>
        <h3>POSTPONEMENT OF EVENT</h3>
        <p>If Nowledge decides to postpone the event for reasons other than Force Majeure, an alternate date will be scheduled within six months from the original date, and the client's participation will be transferred accordingly...</p>
        <p>If the rescheduled event takes place more than six months from the original date, the client may demand a full refund, which will be processed within 30 days of receiving the request...</p>
      </section>
      <section>
        <h3>CANCELLATION OF EVENT</h3>
        <p>If Nowledge cancels the event for any reason, the full amount paid by the client will be refunded within 30 business days of cancellation without the client being able to claim any compensation for the same...</p>
      </section>
      <section>
        <h3>MISCELLANEOUS</h3>
        <p>This agreement constitutes the sole and exclusive agreement between Nowledge and the Client...</p>
        <p>Time is of the essence in relation to this agreement...</p>
        <p>Delivery by email shall constitute delivery hereof...</p>
        <p>All notices sent on a day other than a business day shall be deemed to be received on the immediately following business day...</p>
        <p>The construction, validity, and performance of this agreement shall be governed in all respects by the laws of Bengaluru, India...</p>
      </section>
      <section>
        <h3>GENERAL TERMS & CONDITIONS FOR CLIENTS (SPONSORS/PARTNERS/EXHIBITORS)</h3>
        <p>If the Client cancels the agreement more than 7 days after its signature and more than two months before the Event, they shall be required to pay Nowledge 50% of the total amount mentioned in the booking form...</p>
        <p>If the cancellation is between 30 to 60 days before the event, then 75% of the contract amount remains due...</p>
        <p>If the client does not officially cancel this agreement and does not participate in the event, the invoice will remain payable in full...</p>
        <p>Unless otherwise stated, Nowledge and/or its licensors own the intellectual property rights for all material on Nowledge...</p>
      </section>
      <section>
        <h3>LICENSE</h3>
        <p>Unless otherwise stated, Nowledge and/or its licensors own the intellectual property rights for all material on Nowledge...</p>
        <p>You must not republish material from Nowledge...</p>
        <p>Any such information, content, or material belonging to Nowledge, which we have reason to believe discredits the organization, is defamatory, or breaches intellectual property rights as outlined above, will be considered a violation of the general terms and conditions...</p>
      </section>
      <section>
        <h3>HYPERLINKING</h3>
        <p>The following organizations may link to our Website without prior written approval: Government agencies, Search engines, News organizations, Online directory distributors...</p>
        <p>If you are one of the organizations listed above and are interested in linking to our website, you must inform us by sending an e-mail to Nowledge...</p>
      </section>
      <section>
        <h3>ANTI-HARASSMENT POLICY</h3>
        <p>Nowledge is committed to organizing events free of any form of unlawful harassment or discrimination...</p>
        <p>All attendees are expected to conduct themselves in a professional and appropriate manner...</p>
      </section>
      <section>
        <h3>CONTENT LIABILITY</h3>
        <p>We shall not be held responsible for any content that appears on your Website...</p>
        <p>No link(s) should appear on any Website that may be interpreted as libelous, obscene, or criminal, or which infringes, otherwise violates, or advocates the infringement or other violation of, any third-party rights...</p>
      </section>
      <section>
        <h3>RESERVATION OF RIGHTS</h3>
        <p>We reserve the right to request that you remove all links or any particular link to our Website...</p>
        <p>We also reserve the right to amend these terms and conditions and its linking policy at any time...</p>
      </section>
      <section>
        <h3>REMOVAL OF LINKS FROM OUR WEBSITE</h3>
        <p>If you find any link on our Website that is offensive for any reason, you are free to contact and inform us at any moment...</p>
      </section>
      <section>
        <h3>DISCLAIMER</h3>
        <p>To the maximum extent permitted by applicable law, we exclude all representations, warranties, and conditions relating to our website and the use of this website...</p>
      </section>
      <section>
        <h3>MESSAGES THROUGH EMAIL OR OTHER COMMUNICATIONS</h3>
        <p>Nowledge will not be held responsible for any information or message that is posted or sent to us...</p>
      </section>
      <section>
        <h3>LIMITATION OF LIABILITY</h3>
        <p>Nowledge shall not be liable for any special, consequential, or incidental damages arising from this agreement or the services provided hereunder...</p>
      </section>
      <section>
        <h3>LOCAL LAWS</h3>
        <p>Nowledge has registered and partner offices worldwide and conducts business in several countries where its offices are located...</p>
      </section>
      <section>
        <h3>CONTACT INFORMATION</h3>
        <p>Should you have any queries regarding the General Terms & Conditions, feel free to contact us at: hello@nowledgeglobal.com...</p>
      </section>
    </div>
  </div>
 
  )
}

export default T_and_C