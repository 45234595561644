import React from 'react'
import Prospectia_page1 from '../Website/Prospectia/Prospectia_page1'
import Prospectia_page3 from '../Website/Prospectia/Prospectia_page3';
import Header from '../Website/Header';
import { useEffect } from 'react';
import { useRef } from 'react';
import Footer from '../Website/Footer';
import Prospectia_page2 from '../Website/Prospectia/Prospectia_page2';
const Prospectia = () => {
  const topRef = useRef(null);

  useEffect(() => {
    topRef.current.scrollIntoView({ behavior: 'smooth' });
  }, []);
  return (
    <>
    <div ref={topRef}></div>
    {/* <Header/> */}
    <Prospectia_page1/>
    <Prospectia_page2/>
    <Prospectia_page3/>
    <Footer/>
    </>
  )
}

export default Prospectia