import { useEffect } from 'react';
import { useRef } from 'react';
import React from 'react'
import './Workshop.css'
import henrik from './henrik.png'

const Workshopcont = () => {
    const topRef = useRef(null);

  useEffect(() => {
    topRef.current.scrollIntoView({ behavior: 'smooth' });
  }, []);
  return (
    <>
    <div ref={topRef}></div>
     <div className='row workshop_page'>
        <div className="col-md-8 mx-auto seting-it-out">
            <div className="d-flex justify-evenly flex-wrap justify-center">
            <div className="shape">
                <div className="d-flex flex-wrap justify-evenly pt-5 align-items-center">
                    <div className='col-5 h-1 bg-white'></div>
                    <div className="star"></div>
                    <div className='col-5 h-1 bg-white'></div>
                </div>
                <div className="mt-4">
                    <h3 className='text-center text-white'>
                    Executive <br/>Certification Diploma<br/>with Digital Expert<br/>Accreditation
                </h3>
                </div>
            </div>
            <div className='ml-4 col-8'>
                <h1 className='workshop-heading mt-20 mb-3 display-4' id='font_acumin'>Starclass Workshop on Nuts & Bolts of Digitalization</h1>
                <p className='text-white'>Digitize Everything, everywhere at the same time. Stay ahead of the where and when.</p>
            </div>
            </div>
            <div className='mt-10 px-4'>
                <h4 className='text-white'>
                    Workshop Overview
                </h4>
                <p className='text-white text-justify mt-4'>
                    Digitalization is about connecting the physical-, digital-, and virtual world. Digital technologies has changed every aspect of our lives, society, business, and economy as we know it. Widely misunderstood, digitalization is still in its infant stage! A digital expert makes sense of where and how to deliver digital solution for impact.
                </p>
                <p className='text-white text-justify mt-3'>
                    You will leave the Masterclass with a solid understand of how to digitalize. Anticipating the megatrends of the digital (R)evolution into strategic options. Empowered with the latest practice on how to apply Digitalization topics to define where and how to realize it in operational settings
                </p>
                <p className='text-white text-justify mt-3'>
                    The masterclass is designed to make you a go-to digital sensemaker and translates 3 waves of the digital Revolution and 17 megatrends into strategic choices of how to digitalize. Learn how to define a digital roadmap from digital connectivity and sensors to digital engineering and digital operations.
                </p>
                <p className='text-white text-justify mt-3'>
                    This one of a kind workshop examines digitization in a different setting with the latest management insights and operational practices on what not to do, where to differentiate and what to focus in digitalization. Exploring the strategic options of where to automate, integrate, optimize and align in order to transform and innovate.
                </p>
            </div>
            <div className="mt-10 w-100 banner flex justify-evenly justify_evenly flex-wrap justify_flex_start">
                <div className='inner_border mb-4'>
                    <h1 id='fontchange' className='display-3'>03</h1>
                    <h1 id='font_barlow' className='text-white f_30'>Digital Waves</h1>
                </div>
                <div className='inner_border mb-4'>
                    <h1 id='fontchange' className='display-3'>17</h1>
                    <h1 id='font_barlow' className='display-5 text-white f_30'>Mega Trends</h1>
                </div>
                <div className='inner mb-4'>
                    <h1 id='fontchange' className='display-3'>01</h1>
                    <h1 id='font_barlow' className='display-5 text-white f_30'>Impactful Workshop</h1>
                </div>
            </div>
            <div className="mt-10 flex mb-10 class_for_workshop_henrick">
                <div className="w-40 flex justify-center align-items-center center_any_div">
                    <div className="w-60 w-70 rounded-circle circle">
                        <img className='henrik' src={henrik} alt="" />
                    </div>
                </div>
                <div className="w-60">
                    <h2 id='fontchange' className='workshop_tariner'>Mr. Henrik Von Scheel</h2>
                    <p className='text-white'>Delivered by the globally acclaimed authority on Strategy who ignited the Industry 4.0 and Digital Industry megatrend of today and rightfully referred to as the Originator or Industry 4.0.</p>
                    <div className='trainer_q1'>
                        <p className="text-white">Chairman of 4th Industrial Revolution, WEF. </p>
                    </div>
                    <div className='trainer_q2'>
                        <p className="text-white">Initiator of European Digital Agenda </p>
                    </div>
                    <div className='trainer_q3'>
                        <p className="text-white">Mastermind of Germanys' Digital Agenda </p>
                    </div>
                    <div className='trainer_q4'>
                        <p className="text-white">Recipient of the Financial Times Award: Most Inﬂuential Management Thinker - 2019</p>
                    </div>
                    <div className='trainer_q5'>
                        <p className="text-white">Recipient HRH Mohammed Bin Rashed Al Maktoum's Prestigious Knowledge Award - the Nobel Prize of Knowledge Sharing. </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </>
  )
}

export default Workshopcont