import React from "react";
import mindAhead from "../Website/CopySource/Patners/MindsAhead-1.png";
import SapientPartner from "../Website/CopySource/Patners/Sapient-1.png"

const TechnicalPartners = () => {
  return (
    <section className="ts-event-outcome event-intro">
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <div className="outcome-content">
              <h3 className="sponsor-title text-center sponser_headings_in_partner" id="font_barlow"> 
                {" "}
                Official Event Tech Partner
              </h3>
              <div className="outcome-img ">
                <a href="#" className="sponsors-logo logo_margin">
                  <img className="img-fluid w-35" src={mindAhead} alt="" />
                </a>
              </div>
            </div>
          </div>

          <div className="col-lg-6">
            <div className="outcome-content">
              <h3 className="sponsor-title text-center sponser_headings_in_partner" id="font_barlow">
                {" "}
                Official Technical Training Partner
              </h3>
              <div className="outcome-img ">
                <a href="#" className="sponsors-logo logo_margin">
                  <img className="img-fluid w-35" src={SapientPartner} alt="" />
                </a>
              </div>
            </div>
          </div>

          {/* <div className="col-lg-4">
            <div className="outcome-content">
              <h3 className="sponsor-title text-center">
                {" "}
                Organised By
              </h3>
              <div className="outcome-img ">
                <a href="#" className="sponsors-logo">
                  <img className="img-fluid" src={mindAhead} alt="" />
                </a>
              </div>
            </div>
          </div> */}
          {/* <div className="col-lg-4">
            <div className="">
              <div className="outcome-content ts-exp-content">
               
                <h3 className="sponsor-title text-center">Official Event Tech Partner</h3>
                <p>
                  Reboot Summit Arabia aims to catalyze the Kingdom’s rapidly
                  accelerating digital reality landscape in alignment with Saudi
                  Arabia's ambitious Vision 2030. This two day, cross industry,
                  technology summit is scheduled as part of the Riyadh
                  Technology Week will host pre-qualified, invite-only attendees
                  who are leading technologists, futurists and the most
                  impactful names in tech striving to change the way we live,
                  study, work.
                </p>
              </div>
            </div>
          </div> */}
          {/* <div className="col-lg-4">
            <div className="outcome-content">
              <div className="outcome-img">
                <h3 className="sponsor-title text-center">Organised By</h3>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </section>
  );
};

export default TechnicalPartners;
