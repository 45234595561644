import React from 'react'
import '../Disclaimer/T_and_C.css'

const CookiesPolicy = () => {
    return (
        <div className="Terms_container">
        <header>
          <h1>Cookies Policies</h1>
        </header>
        <div className="content">
          <section>
            <p className='text-black'>We utilize cookies on our website. By accessing Nowledge Global or Nowledge Global-operated event websites, you consent to the use of cookies in accordance with Nowledge Global's Privacy Policy.</p>
          </section>
          <section>
            <p className='text-black'>Cookies are small packets of data containing information about a user, which are stored by the web browser. Most interactive websites use cookies to retrieve user details for each visit. These cookies are not harmful to users' devices or files; rather, they are employed by Nowledge Global's services to enable the functionality of certain areas, facilitating a smoother experience for visitors and providing personalized content.</p>
          </section>
          <section>
            <p className='text-black'>Nowledge Global places great importance on the privacy of users who browse our website. Therefore, we are dedicated to safeguarding the personal and business information of our users.</p>
          </section>
          <section>
            <p className='text-black'>You have the option to configure your web browser to refuse cookies or to receive alerts when cookies are being sent.</p>
          </section>
          <section>
            <p className='text-black'>For further details, please refer to our Privacy Policy page.</p>
          </section>
          <section>
            <p className='text-black'>If you have any questions regarding this Cookie Policy, please don't hesitate to contact us at: hello@nowledgeglobal.com.</p>
          </section>
        </div>
      </div>
     
      )
}

export default CookiesPolicy