import React from 'react'
import axios from "axios";
import { useEffect, useState } from "react";
import { BsFillArchiveFill, BsFillGrid3X3GapFill, BsPeopleFill, BsFillBellFill }
    from 'react-icons/bs'
import {ResponsiveContainer} from 'recharts';

function Home() {

    const [activeButton, setActiveButton] = useState("Button-1");
    const [Data,setData]=useState([])
  
    async function getdata(APIuRL) {
      try {
        const response = await axios.get(APIuRL);
        setData(response.data)
      } catch (error) {
        console.error(error.response.data);
      }
    }
    async function aproveUserHendler(key) {
      try {
        console.log(key);
        const response = await axios.post(`http://195.35.22.195:3001/aprove/${key}`);
        console.log(response.data);
        getdata("http://195.35.22.195:3001/notaprovedusers");
      } catch (error) {
        console.error(error.response.data);
      }
    }
  
    useEffect(()=>{
      getdata("http://195.35.22.195:3001/aprovedusers")

    },[])
  
  
    function hendleclick(event) {
      if (event.target.id==="Approved") {
        setActiveButton("Button-1")
        getdata("http://195.35.22.195:3001/aprovedusers")
  
      }
      if (event.target.id==="NotApproved") {
        setActiveButton("Button-2")
        getdata("http://195.35.22.195:3001/notaprovedusers")
      }
    }
    return (
        <main className='main-container'>
            <div className='main-title'>
                <h3>DASHBOARD</h3>
            </div>

            <div className='main-cards'>
                <div className='card'>
                    <div className='card-inner'>
                        <h1 id="Approved" onClick={hendleclick} className={`text-slate-200 cursor-pointer ${activeButton === 'Button-1' ? ' p-1  text-black font-bold ' : ''}`}>Approved User</h1>
                        <BsFillArchiveFill className='card_icon' />
                    </div>
                    <h1>{Data.length}</h1>
                </div>
                <div className='card'>
                    <div className='card-inner'>
                        <h1 id="NotApproved" onClick={hendleclick} className={`text-slate-200 cursor-pointer ${activeButton === 'Button-2' ? ' p-1  text-black font-bold ' : ''}`}>Not Approved User</h1>
                        <BsFillGrid3X3GapFill className='card_icon' />
                    </div>
                    <h1>{Data.length}</h1>
                </div>
                <div className='card'>
                    <div className='card-inner'>
                        <h3>Speaker</h3>
                        <BsPeopleFill className='card_icon' />
                    </div>
                    <h1>33</h1>
                </div>
                <div className='card'>
                    <div className='card-inner'>
                        <h3>Total user</h3>
                        <BsFillBellFill className='card_icon' />
                    </div>
                    <h1>42</h1>
                </div>
            </div>

            <div className='charts'>
                <ResponsiveContainer width="100%" height="100%" >
                   <div >
                    
                   {
                        Data.map(item => (
                            <div key={item.MemberId} className='flex gap-11'>
                                <p className="text-black w-[12vw]">{item.MemberEmail}</p>
                                {item.isAproved===false && <button key={item.MemberId} onClick={()=>{aproveUserHendler(item.MemberId)}}>Accept</button>}
                                
                            </div>
                        ))
                    }
                   </div>
                </ResponsiveContainer>

                <ResponsiveContainer width="100%" height="100%">
                </ResponsiveContainer>

            </div>
        </main>
    )
}

export default Home