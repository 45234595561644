import Header from '../Website/Header';
import Banner from '../Website/Banner';
import Intro from '../Website/Intro';
import FunFact from '../Website/FunFact';
import Speakers from '../Website/Speakers';
import Experience from '../Website/Experience';
import Schedule from '../Website/Schedule';
import Pricing from '../Website/Pricing';
import Blog from '../Website/Blog';
import Sponsors from '../Website/Sponsors';
import MapDirection from '../Website/MapDirection';
import Footer from '../Website/Footer';
import { useEffect } from 'react';
import { useRef } from 'react';

export const Home = () => {
  const topRef = useRef(null);

  useEffect(() => {
    topRef.current.scrollIntoView({ behavior: 'smooth' });
  }, []);
  return (
    <>
    <div ref={topRef}></div>
        <Header/>
        <Banner/>
        <Intro/>
        <FunFact/>
        <Speakers/>
        <Experience/>
        <Schedule/>
        <Pricing/>
        <Blog/>
        <Sponsors/>
        <MapDirection/>
        <Footer/>
    </>
  )
}
