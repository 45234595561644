import React from 'react';
import './Page1.css';
import logo from '../CopySource/images/prospectia/12.png';
import image1 from '../CopySource/images/prospectia/1.png';
import image2 from '../CopySource/images/prospectia/2.png';
import image4 from '../CopySource/images/prospectia/4.png';
import image3 from '../CopySource/images/prospectia/3.png';

const Prospectia_page1 = () => {
  return (
    <div className='page1_main_container'>
      <div className="image1">
        <img src={image1} alt="Precision Targeting" />
      </div>
      <div className="image2">
        <img src={image2} alt="Tailored Solutions" />
      </div>
      <div className="image3">
        <img src={image4} alt="Enhanced Efficiency" />
      </div>
      <div className="image4">
        <img src={image3} alt="Measurable Results" />
      </div>
      <div className='right_aligned_section'>
        {/* Right aligned section */}
        <img src={logo} alt="Prospectia Logo" className="logo_image" />
        <div className="contact_info">
          +91 8048653769 <br />
          prospectia@nowledge.global <br />
          Bangalore, India
        </div>
      </div>

      <div className='left_aligned_section'>
        {/* Left aligned section */}
        <h1 className="welcome_text">Welcome to</h1>
        <h2 className="prospectia_text">Prospectia</h2>
        <h3 className="excellence_text">Demand generation excellence that you deserve.</h3>
      </div>
    </div>
  );
};

export default Prospectia_page1;
