import React from 'react';

const SignificanceKSA = () => {
  return (
    <div className="container mt-10">
      <div className="row justify-content-center">
        <div className='col-lg-8 col-md-10 col-sm-12'>
          <h2 className="section-title text-center">
            Significance of Reboot 2024 in Saudi Arabia
          </h2>
          <div className="mx-auto mb-4">
            <div className="d-inline">
              <h4 className=''></h4>
            </div>
            <p className='text-justify'><strong className='text-black'>10X Growth: </strong> Analysts project a 10X increase in Digital Transformation investments in MENA - from 29.2 Bn USD in 2022 to 298.2 Bn over the next 9 Years.</p>
            <p className='text-justify'><strong className='text-black'>When it rains it pours!:</strong> The cloud segment is poised to grow at the highest CAGR of 24.2% over the forecast period</p>
            <p className='text-justify'><strong className='text-black'>From Sands to Silicon:</strong> With Digital Transformation being one of the primary pillars of the Kingdom’s Vision 2030 for a diversified economy, fresh and continued investments in new Data Centers, Cloud Infrastructure and Next Generation Data Networks continue to position Saudi Arabia in the center-stage of emerging technology adoption in both public and private sectors. which will also subsequently impact the continuity of the growing ICT spending over the next few years.</p>
            <p className="text-justify"><strong className='text-black'>The Network Effect:</strong> The kingdom's managed services market is forecast to grow at a rapid CAGR of 13.3% through 2027 - essentially enabling and positively challenging services providers to leverage new technologies and provide value-efficient services to the region's diverse interests and challenges.</p>
            <p className="text-justify">“As organizations continue to embrace AI, advanced analytics, enhanced security, and cloud solutions, opportunities open for global and local systems integrators to help businesses drive effective implementations,”</p>
            <p className="text-justify">“Businesses are making strategic decisions to utilize cloud services from different cloud providers and run their workloads in a mix of on-premises and various cloud environments, leading to greater complexity. Managing multicloud and hybrid cloud is a challenge, and this will drive increased demand for cloud professional services.” IDC Research</p>
            <p className="text-justify">The digital transformation market in Saudi Arabia is poised for exponential growth, with projections indicating a value of 80 billion Saudi Riyals ($21.3 billion) by 2030. This remarkable potential for economic expansion positions the Kingdom as a prime destination for companies seeking sustainable growth.</p>
            <p className="text-justify"><strong className='text-black'>Industrial Automation:</strong> As part of the Saudi National Industrial Strategy, the Ministry of Industry and Mineral Resources has initiated am ambitious project to automate 4000 </p>
            <p className="text-justify"><strong className='text-black'>Digitization at the forefront of Industrial Development:</strong> Saudi Arabia is laying the groundwork for digital transformation in important industries including manufacturing, health, transportation, logistics, and energy.   Analysts predict that AI will contribute USD135.2 billion, or 12.4%, to the Kingdom's GDP in the next 7 years.</p>
            <p className="text-justify"><strong className='text-black'>Factories of the Future:</strong> With rapid adoption of industrial automation, the region is witnessing a growing demand for deployments of automation solutions and services, especially across industries such as Energy (Upstream and Downstream), Petrochemicals, Manufacturing and Supply Chain among others, leading to an imperative demand for Cyber Security advancement and deployment.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignificanceKSA;
