import React from 'react'
import design from '../Website/CopySource/images/pricing/dot.png'
import shape2 from '../Website/CopySource/images/shap/pricing_memphis1.png'

const Pricing = () => {
  return (
    <>
      {/* ts pricing start*/}
      <section id='Tickets_Price'
        className="ts-pricing gradient"
        style={{ backgroundImage: "url(./images/pricing/pricing_img.jpg)" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h2 className="section-title  white" id='reverse_your_space'>
                <span className='view_all_button_for_text_deco'>RESERVE YOUR SEAT</span>
                Get your Ticket
              </h2>
            </div>
            {/* section title end*/}
          </div>
          {/* col end*/}
          {/* row end*/}
          <div className="row">
            <div
              className="col-lg-4 wow fadeInUp"
              data-wow-duration="1.5s"
              data-wow-delay="400ms"
            >
              <div className="pricing-item">
                <img className="pricing-dot " src={design} alt="" />
                <div className="ts-pricing-box ticket_pricing_box buy_ticket_parent">
                  <div className="ts-pricing-header">
                    <h2 className="ts-pricing-name">VIP Conference Pass</h2>
                    {/* <h3 className="ts-pricing-price">
                      <span className="currency">$</span>1699
                    </h3> */}
                  </div>
                  <div className="ts-pricing-progress">
                    <p className="amount-progres-text">
                      Exclusive access to
                    </p>

                    <ul className='ticketsUl'>
                      <li>Conferencing Sessions</li>
                      <li>Networking Luncheon Lounge</li>
                      <li>Exclusive Access to Prospectia Meeting Lounge</li>

                    </ul>

                    {/* <div className="ts-progress">
                      <div
                        className="ts-progress-inner"
                        style={{ width: "100%" }}
                      />
                    </div> */}

                    <p className="ts-pricing-value"></p>
                  </div>
                  <div className="promotional-code buy_ticket_ref buy_ticket_ref">
                    {/* <p className="promo-code-text">Enter Promotional Code</p> */}
                    <a href="#" className="btn pricing-btn">
                      Buy Ticket
                    </a>
                    {/* <p className="vate-text">Available until March 31st</p> */}
                  </div>
                </div>

                {/* ts pricing box*/}
                <img
                  className="pricing-dot1 "
                  src={design}
                  alt=""
                />
              </div>
            </div>
            {/* col end*/}
            <div
              className="col-lg-4 wow fadeInUp"
              data-wow-duration="1.5s"
              data-wow-delay="500ms"
            >
              <div className="pricing-item">
                <img className="pricing-dot " src={design} alt="" />
                <div className="ts-pricing-box ticket_pricing_box buy_ticket_parent">
                  <span className="big-dot" />
                  <div className="ts-pricing-header">
                    <h2 className="ts-pricing-name">VIP Workshop Pass</h2>
                    {/* <h3 className="ts-pricing-price">
                      <span className="currency">$</span>1499
                    </h3> */}
                  </div>
                  <div className="ts-pricing-progress">
                    <p className="amount-progres-text">
                      Exclusive access to
                    </p>
                    <ul className='ticketsUl'>
                      <li>Starclass Workshop - Nuts and Bolts Of Digitalization</li>
                      <li>Executive Certification Diploma with Digitization Expert Accreditation</li>
                      <li> Networking Luncheon Lounge</li>

                    </ul>

                    {/* <div className="ts-progress">
                      <div className="ts-progress-inner" style={{ width: "75%" }} />
                    </div> */}

                    <p className="ts-pricing-value"></p>
                  </div>

                  <div className="promotional-code buy_ticket_ref">
                    {/* <p className="promo-code-text">Enter Promotional Code</p> */}
                    <a href="#" className="btn pricing-btn">
                      Buy Ticket
                    </a>
                    {/* <p className="vate-text">Available until May 31st</p> */}
                  </div>
                </div>

                {/* ts pricing box*/}
                <img
                  className="pricing-dot1 "
                  src={design}
                  alt=""
                />
              </div>
            </div>
            {/* col end*/}
            <div
              className="col-lg-4 wow fadeInUp"
              data-wow-duration="1.5s"
              data-wow-delay="600ms"
            >
               <div className="pricing-item">
                <img className="pricing-dot " src={design} alt="" />
                <div className="ts-pricing-box ticket_pricing_box buy_ticket_parent">
                  <span className="big-dot" />
                  <div className="ts-pricing-header">
                    <h2 className="ts-pricing-name">Executive VIP Pass</h2>
                    {/* <h3 className="ts-pricing-price">
                      <span className="currency">$</span>2999
                    </h3> */}
                  </div>
                  <div className="ts-pricing-progress">
                    <p className="amount-progres-text">
                      Exclusive access to
                    </p>
                    <ul className='ticketsUl'>
                      <li>All Benefits of VIP Conference Pass</li>
                      <li>All Benefits of VIP Workshop Pass</li>
                      {/* <li> Networking Luncheons</li>
                      <li>Starclass Workshop</li>
                      <li>Exclusive Prospectia On Demand Access for 3
                        months</li> */}

                    </ul>

                    {/* <div className="ts-progress">
                      <div className="ts-progress-inner" style={{ width: "75%" }} />
                    </div> */}

                    <p className="ts-pricing-value"></p>
                  </div>

                  <div className="promotional-code buy_ticket_ref">
                    {/* <p className="promo-code-text">Enter Promotional Code</p> */}
                    <a href="#" className="btn pricing-btn">
                      Buy Ticket
                    </a>
                    {/* <p className="vate-text">Available until May 31st</p> */}
                  </div>
                </div>

                {/* ts pricing box*/}
                <img
                  className="pricing-dot1 "
                  src={design}
                  alt=""
                />
              </div>
            </div>
            {/* col end*/}
          </div>
        </div>
        {/* container end*/}
        <div
          className="speaker-shap wow fadeInUp"
          data-wow-duration="1.5s"
          data-wow-delay="400ms"
        >
          <img className="shap2" src={shape2} alt="" />
        </div>
      </section>
      {/* ts pricing end*/}
    </>

  )
}

export default Pricing