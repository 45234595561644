import React, { useState } from "react";
import axios from "axios";
import "./PopupForm.css";
import svgImage from "./CopySource/images/thankyou_tick.svg";
const PopupForm = ({ onClose }) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [designation, setDesignation] = useState("");
  const [Organization, setOrganization] = useState("");
  const [role, setRole] = useState("");
  const [formsubmitted, setformsubmitted] = useState(false);
  const [formerr, setformerr] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      // Send form data to server-side script
      const res = await axios.post("https://reboot-summit.com:3002/sendemail", {
        name,
        email,
        phone,
        designation,
        Organization,
        role,
      });
      console.log(res);

      if (res.status == 200) {
        setLoading(false);
        setformsubmitted(true);
      } else {
        setLoading(false);
        setformerr(true);
      }
    } catch (error) {
      setLoading(false);
      setformerr(true);
      console.error("Error submitting form:", error);
    }
  };

  return (
    <div className="popup">
      <div className="popup-content">
        {!formsubmitted && !formerr && !loading && (
          <>
            <h2 className="text-center pop_up_heading">Register</h2>
            <span className="close" onClick={onClose}>
              &times;
            </span>
            <form onSubmit={handleSubmit}>
              <div className="fieldclass input-effect">
                <input
                  className="effect-16"
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                  placeholder="Name"
                />
                <span className="focus-border"></span>
              </div>

              <div className="fieldclass input-effect">
                <input
                  className="effect-16"
                  type="text"
                  value={designation}
                  onChange={(e) => setDesignation(e.target.value)}
                  required
                  placeholder="Designation"
                />
                <span className="focus-border"></span>
              </div>

              <div className="fieldclass input-effect">
                <input
                  className="effect-16"
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                  placeholder="Email"
                />
                <span className="focus-border"></span>
              </div>

              <div className="fieldclass input-effect">
                <input
                  className="effect-16"
                  type="tel"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  required
                  placeholder="Phone"
                />
                <span className="focus-border"></span>
              </div>

              <div className="fieldclass input-effect">
                <input
                  className="effect-16"
                  type="text"
                  value={Organization}
                  onChange={(e) => setOrganization(e.target.value)}
                  required
                  placeholder="Organization"
                />
                <span className="focus-border"></span>
              </div>

              <div className="fieldclass input-effect">
                <select
                  className="effect-16"
                  value={role}
                  onChange={(e) => setRole(e.target.value)}
                  required
                >
                  <option value="">Select Role</option>
                  <option value="Sponsor">Sponsor</option>
                  <option value="Delegate">Delegate</option>
                  <option value="Speaker">Speaker</option>
                  <option value="Industry Partner">Industry Partner</option>
                  <option value="Media Partner">Media Partner</option>
                </select>
                <span className="focus-border"></span>
              </div>

              <button type="submit" className="view_all_button">
                Submit
              </button>
            </form>
          </>
        )}
        {loading && (
          <div className="loader_parent_div flex align-items-center justify-content-center h-100">
            <h3 className="loader"></h3>
          </div>
        )}
        {formsubmitted && (
          <div className="w-100 h-100">
            <span className="close" onClick={onClose}>
              &times;
            </span>
            <div className="thank-you-message">
              <div className="tickMark">
                <iframe src="https://lottie.host/embed/de2a6234-cbe8-4027-9235-d8f12ef80674/3ghdpOD9Cw.json"></iframe>
              </div>

              <h1>You're all set!</h1>
              <p className="pt-2">
                Thank you for registering your interest in being a part of
                Reboot Summit Arabia. You will shortly be contacted by our event
                team relevant to your participation type.
              </p>
              <p className="pt-2">
                Meanwhile, feel free to engage with us and stay connected with
                the latest happenings on social platforms.
              </p>
              <div className="ts-footer-social text-center mb-30">
                <ul>
                  <li>
                    <a href="#">
                      <i className="fa fa-linkedin text-black" />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fa fa-twitter text-black" />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fa fa-instagram text-black" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        )}
        {formerr && (
          <div className="w-100 h-100">
            <span className="close" onClick={onClose}>
              &times;
            </span>
            <h3> Error</h3>
            <p>please try again later</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default PopupForm;
