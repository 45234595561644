import { useState } from 'react';
import { React, useEffect } from './imports';
import sp2 from './CopySource/images/speakers/speaker2.jpg'
import { Link } from 'react-router-dom';

const Schedule = () => {
  const[Tabdate,setTabdate]=useState(1);
  return (
    <>
      {/* ts experience start*/}
      <section className="ts-schedule">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 mx-auto">
              <h2 className="section-title">
                <span>Schedule Details</span>
                Program Agenda
              </h2>
              {/* <p>Together. lets commit to making participation an inclusive experience for everyone,
                regardless Of level Of experience. gender, identity. orientation and expression.</p> */}
              <div className="ts-schedule-nav">
                <ul className="nav nav-tabs justify-content-center" role="tablist">
                  <li className="nav-item" id='tab1' style={{cursor:"pointer"}}  onClick={()=>{
                    setTabdate(1);
                  }}>
                    <a
                      className={Tabdate === 1 ? "active" : ""}
                      title="Click Me"
                      // href="#date1"
                      role="tab"
                      data-toggle="tab"
                    >
                      <h3>Day 1</h3>
                      <span>13 Aug 2024</span>
                    </a>
                  </li>
                  <li className="nav-item" style={{cursor:"pointer"}} onClick={()=>{
                    setTabdate(2);
                  }}>
                    <a
                      className={Tabdate === 2 ? "active" : ""}
                      // href="#date2"
                      title="Click Me"
                      role="tab"
                      data-toggle="tab"
                    >
                      {" "}
                      <h3>Day 2</h3>
                      <span>14 Aug 2024</span>
                    </a>
                  </li>
                  {/* <li className="nav-item">
                    <a
                      className=""
                      href="#date3"
                      title="Click Me"
                      role="tab"
                      data-toggle="tab"
                    >
                      <h3>7th June</h3>
                      <span>Sunday</span>
                    </a>
                  </li> */}
                </ul>
                {/* Tab panes */}
              </div>
            </div>
            {/* col end*/}
          </div>
          {/* row end*/}
          <div className="row flex flex-wrap-reverse">
            <div className="col-lg-12">
              <div className="tab-content schedule-tabs schedule-tabs-item">
                { Tabdate===1 && 
                  <div role="tabpanel" className={Tabdate === 1 ? "tab-pane active" : "tab-pane"} id="date1">
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="schedule-listing-item schedule-left">
                          <img
                            className="schedule-slot-speakers"
                            src={sp2}
                            alt=""
                          />
                          <span className="schedule-slot-time">09:00 - 09:20</span>
                          <h3 className="schedule-slot-title">Welcome Address</h3>
                          {/* <h4 className="schedule-slot-name">@ Henrikon Rebecca</h4> */}
                          <p>
                            {/* Any text here */}
                          </p>
                        </div>
                      </div>
                      {/* col end*/}
                      <div className="col-lg-6">
                        <div className="schedule-listing-item schedule-right">
                          <img
                            className="schedule-slot-speakers"
                            src={sp2}
                            alt=""
                          />
                          <span className="schedule-slot-time">08:00 - 09:00</span>
                          <h3 className="schedule-slot-title">
                            Registrations and Welcome Networking Coffee
                          </h3>
                          {/* <h4 className="schedule-slot-name">@ Johnsson Agaton</h4> */}
                          <p>
                            {/* Any text here */}
                          </p>
                        </div>
                      </div>
                      {/* col end*/}
                      {/* <div className="col-lg-6">
                        <div className="schedule-listing-item schedule-left">
                          <img
                            className="schedule-slot-speakers"
                            src="images/speakers/speaker3.jpg"
                            alt=""
                          />
                          <span className="schedule-slot-time">09:40 - 10:00</span>
                          <h3 className="schedule-slot-title">
                            Available Solution Presentation Slot
                          </h3>
                         
                          <p>
                            
                          </p>
                        </div>
                      </div> */}
                      {/* col end*/}
                      {/* <div className="col-lg-6">
                        <div className="schedule-listing-item schedule-right">
                          <img
                            className="schedule-slot-speakers"
                            src="images/speakers/speaker4.jpg"
                            alt=""
                          />
                          <span className="schedule-slot-time">09:20 - 09:40</span>
                          <h3 className="schedule-slot-title">
                            Inaugural Keybote Address - Embracing the Digital Age: Saudi Arabia's Vision 2030 for a Thriving Tech Ecosystem
                          </h3>
                          <h4 className="schedule-slot-name">@ Fredric Martinsson</h4>
                          <p>
                            This opening keynote address sets the stage for the conference by outlining Saudi Arabia's ambitious Vision 2030 plan. It explores how technology is crucial for achieving the Vision's goals of economic diversification and highlights the Kingdom's initiatives to foster a strong domestic tech sector.
                          </p>
                        </div>
                      </div> */}
                      {/* col end*/}

                      {/* <div className="col-lg-6">
                        <div className="schedule-listing-item schedule-left">
                          <img
                            className="schedule-slot-speakers"
                            src="images/speakers/speaker3.jpg"
                            alt=""
                          />
                          <span className="schedule-slot-time">09:40 - 10:00</span>
                          <h3 className="schedule-slot-title">
                            Available Solution Presentation Slot
                          </h3>
                        
                          <p>
                            
                          </p>
                        </div>
                      </div> */}
                      {/* col end*/}
                      {/* <div className="col-lg-6">
                        <div className="schedule-listing-item schedule-right">
                          <img
                            className="schedule-slot-speakers"
                            src="images/speakers/speaker4.jpg"
                            alt=""
                          />
                          <span className="schedule-slot-time">09:20 - 09:40</span>
                          <h3 className="schedule-slot-title">
                            Inaugural Keybote Address - Embracing the Digital Age: Saudi Arabia's Vision 2030 for a Thriving Tech Ecosystem
                          </h3>
                          <h4 className="schedule-slot-name">@ Fredric Martinsson</h4>
                          <p>
                            This opening keynote address sets the stage for the conference by outlining Saudi Arabia's ambitious Vision 2030 plan. It explores how technology is crucial for achieving the Vision's goals of economic diversification and highlights the Kingdom's initiatives to foster a strong domestic tech sector.
                          </p>
                        </div>
                      </div> */}
                      {/* col end*/}
                      {/* <div className="col-lg-6">
                        <div className="schedule-listing-item schedule-left">
                          <img
                            className="schedule-slot-speakers"
                            src="images/speakers/speaker3.jpg"
                            alt=""
                          />
                          <span className="schedule-slot-time">09:40 - 10:00</span>
                          <h3 className="schedule-slot-title">
                            Available Solution Presentation Slot
                          </h3>
                         
                          <p>
                            
                          </p>
                        </div>
                      </div> */}
                      {/* col end*/}
                      {/* <div className="col-lg-6">
                        <div className="schedule-listing-item schedule-right">
                          <img
                            className="schedule-slot-speakers"
                            src="images/speakers/speaker4.jpg"
                            alt=""
                          />
                          <span className="schedule-slot-time">09:20 - 09:40</span>
                          <h3 className="schedule-slot-title">
                            Inaugural Keybote Address - Embracing the Digital Age: Saudi Arabia's Vision 2030 for a Thriving Tech Ecosystem
                          </h3>
                          <h4 className="schedule-slot-name">@ Fredric Martinsson</h4>
                          <p>
                            This opening keynote address sets the stage for the conference by outlining Saudi Arabia's ambitious Vision 2030 plan. It explores how technology is crucial for achieving the Vision's goals of economic diversification and highlights the Kingdom's initiatives to foster a strong domestic tech sector.
                          </p>
                        </div>
                      </div> */}
                      {/* col end*/}
                      {/* <div className="col-lg-6">
                        <div className="schedule-listing-item schedule-left">
                          <img
                            className="schedule-slot-speakers"
                            src="images/speakers/speaker3.jpg"
                            alt=""
                          />
                          <span className="schedule-slot-time">09:40 - 10:00</span>
                          <h3 className="schedule-slot-title">
                            Available Solution Presentation Slot
                          </h3>
                          
                          <p>
                            
                          </p>
                        </div>
                      </div> */}
                      {/* col end*/}
                      {/* <div className="col-lg-6">
                        <div className="schedule-listing-item schedule-right">
                          <img
                            className="schedule-slot-speakers"
                            src="images/speakers/speaker4.jpg"
                            alt=""
                          />
                          <span className="schedule-slot-time">09:20 - 09:40</span>
                          <h3 className="schedule-slot-title">
                            Inaugural Keybote Address - Embracing the Digital Age: Saudi Arabia's Vision 2030 for a Thriving Tech Ecosystem
                          </h3>
                          <h4 className="schedule-slot-name">@ Fredric Martinsson</h4>
                          <p>
                            This opening keynote address sets the stage for the conference by outlining Saudi Arabia's ambitious Vision 2030 plan. It explores how technology is crucial for achieving the Vision's goals of economic diversification and highlights the Kingdom's initiatives to foster a strong domestic tech sector.
                          </p>
                        </div>
                      </div> */}
                      {/* col end*/}
                      {/* <div className="col-lg-6">
                        <div className="schedule-listing-item schedule-left">
                          <img
                            className="schedule-slot-speakers"
                            src="images/speakers/speaker3.jpg"
                            alt=""
                          />
                          <span className="schedule-slot-time">09:40 - 10:00</span>
                          <h3 className="schedule-slot-title">
                            Available Solution Presentation Slot
                          </h3>
                          
                          <p>
                           
                          </p>
                        </div>
                      </div> */}
                      {/* col end*/}
                      {/* <div className="col-lg-6">
                        <div className="schedule-listing-item schedule-right">
                          <img
                            className="schedule-slot-speakers"
                            src="images/speakers/speaker4.jpg"
                            alt=""
                          />
                          <span className="schedule-slot-time">09:20 - 09:40</span>
                          <h3 className="schedule-slot-title">
                            Inaugural Keybote Address - Embracing the Digital Age: Saudi Arabia's Vision 2030 for a Thriving Tech Ecosystem
                          </h3>
                          <h4 className="schedule-slot-name">@ Fredric Martinsson</h4>
                          <p>
                            This opening keynote address sets the stage for the conference by outlining Saudi Arabia's ambitious Vision 2030 plan. It explores how technology is crucial for achieving the Vision's goals of economic diversification and highlights the Kingdom's initiatives to foster a strong domestic tech sector.
                          </p>
                        </div>
                      </div> */}

                      {/* col end*/}
                      {/* <div className="col-lg-6">
                        <div className="schedule-listing-item schedule-left">
                          <img
                            className="schedule-slot-speakers"
                            src="images/speakers/speaker3.jpg"
                            alt=""
                          />
                          <span className="schedule-slot-time">09:40 - 10:00</span>
                          <h3 className="schedule-slot-title">
                            Available Solution Presentation Slot
                          </h3>
                         
                          <p>
                            
                          </p>
                        </div>
                      </div> */}
                      {/* col end*/}
                      {/* <div className="col-lg-6">
                        <div className="schedule-listing-item schedule-right">
                          <img
                            className="schedule-slot-speakers"
                            src="images/speakers/speaker4.jpg"
                            alt=""
                          />
                          <span className="schedule-slot-time">09:20 - 09:40</span>
                          <h3 className="schedule-slot-title">
                            Inaugural Keybote Address - Embracing the Digital Age: Saudi Arabia's Vision 2030 for a Thriving Tech Ecosystem
                          </h3>
                          <h4 className="schedule-slot-name">@ Fredric Martinsson</h4>
                          <p>
                            This opening keynote address sets the stage for the conference by outlining Saudi Arabia's ambitious Vision 2030 plan. It explores how technology is crucial for achieving the Vision's goals of economic diversification and highlights the Kingdom's initiatives to foster a strong domestic tech sector.
                          </p>
                        </div>
                      </div> */}
                      {/* col end*/}
                    </div>
                    {/* row end*/}
                    <div className="schedule-listing-btn">
                      <Link to='/Agenda' className="btn">
                        More Details
                      </Link>
                    </div>
                  </div>
                }
                {/* tab pane end*/}
                { Tabdate===2 && 
                  <div role="tabpanel" className={Tabdate === 2 ? "tab-pane active" : "tab-pane"} id="date2">
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="schedule-listing-item schedule-left">
                          <img
                            className="schedule-slot-speakers"
                            src={sp2}
                            alt=""
                          />
                          <span className="schedule-slot-time">09:00 - 09:20</span>
                          <h3 className="schedule-slot-title">Welcome Address</h3>
                          {/* <h4 className="schedule-slot-name">@ Rebecca Henrikon</h4> */}
                          <p></p>
                        </div>
                      </div>
                      {/* col end*/}
                      <div className="col-lg-6">
                        <div className="schedule-listing-item schedule-right">
                          <img
                            className="schedule-slot-speakers"
                            src={sp2}
                            alt=""
                          />
                          <span className="schedule-slot-time">08:00 - 09:00</span>
                          <h3 className="schedule-slot-title">
                            Registrations and Welcome Networking Coffee
                          </h3>
                          {/* <h4 className="schedule-slot-name">@ Johnsson Agaton</h4> */}
                          <p>
                            {/* Any text here */}
                          </p>
                        </div>
                      </div>
                      {/* col end*/}
                      {/* <div className="col-lg-6">
                        <div className="schedule-listing-item schedule-left">
                          <img
                            className="schedule-slot-speakers"
                            src="images/speakers/speaker7.jpg"
                            alt=""
                          />
                          <span className="schedule-slot-time">04.30 - 05.30 PM</span>
                          <h3 className="schedule-slot-title">
                            Cultures of Creativity
                          </h3>
                          <h4 className="schedule-slot-name">@ Melisa Lundryn</h4>
                          <p>
                            How you transform your business technolog consumer habits
                            industry dynamics change Find out from those leading the
                            charge How you
                          </p>
                        </div>
                      </div> */}
                      {/* col end*/}
                      {/* <div className="col-lg-6">
                        <div className="schedule-listing-item schedule-right">
                          <img
                            className="schedule-slot-speakers"
                            src="images/speakers/speaker8.jpg"
                            alt=""
                          />
                          <span className="schedule-slot-time">05.30 - 06.30 PM</span>
                          <h3 className="schedule-slot-title">
                            Human Centered Design
                          </h3>
                          <h4 className="schedule-slot-name">@ Agaton Johnsson</h4>
                          <p>
                            How you transform your business technolog consumer habits
                            industry dynamics change Find out from those leading the
                            charge How you
                          </p>
                        </div>
                      </div> */}
                      {/* col end*/}
                    </div>
                    {/* row end*/}
                    <div className="schedule-listing-btn">
                      <Link to='/Agenda' className="btn">
                        More Details
                      </Link>
                    </div>
                  </div>
                }
                <div role="tabpanel" className="tab-pane" id="date3">
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="schedule-listing-item schedule-left">
                        <img
                          className="schedule-slot-speakers"
                          src="images/speakers/speaker1.jpg"
                          alt=""
                        />
                        <span className="schedule-slot-time">10.30 - 11.30 AM</span>
                        <h3 className="schedule-slot-title">Marketing Matters</h3>
                        <h4 className="schedule-slot-name">@ Rebecca Henrikon</h4>
                        <p>
                          How you transform your business technolog consumer habits
                          industry dynamics change Find out from those leading the
                          charge How you
                        </p>
                      </div>
                    </div>
                    {/* col end*/}
                    <div className="col-lg-6">
                      <div className="schedule-listing-item schedule-right">
                        <img
                          className="schedule-slot-speakers"
                          src="images/speakers/speaker2.jpg"
                          alt=""
                        />
                        <span className="schedule-slot-time">11.30 - 12.30 PM</span>
                        <h3 className="schedule-slot-title">
                          Reinventing Experiences
                        </h3>
                        <h4 className="schedule-slot-name">@ Hall Building</h4>
                        <p>
                          How you transform your business technolog consumer habits
                          industry dynamics change Find out from those leading the
                          charge How you
                        </p>
                      </div>
                    </div>
                    {/* col end*/}
                    <div className="col-lg-6">
                      <div className="schedule-listing-item schedule-left">
                        <img
                          className="schedule-slot-speakers"
                          src="images/speakers/speaker3.jpg"
                          alt=""
                        />
                        <span className="schedule-slot-time">01.30 - 02.30 PM</span>
                        <h3 className="schedule-slot-title">
                          Cultures of Creativity
                        </h3>
                        <h4 className="schedule-slot-name">@ Fredric Martinsson</h4>
                        <p>
                          How you transform your business technolog consumer habits
                          industry dynamics change Find out from those leading the
                          charge How you
                        </p>
                      </div>
                    </div>
                    {/* col end*/}
                    <div className="col-lg-6">
                      <div className="schedule-listing-item schedule-right">
                        <img
                          className="schedule-slot-speakers"
                          src="images/speakers/speaker4.jpg"
                          alt=""
                        />
                        <span className="schedule-slot-time">03.30 - 04.30 PM</span>
                        <h3 className="schedule-slot-title">
                          Human Centered Design
                        </h3>
                        <h4 className="schedule-slot-name">@ Henrikon Rebecca</h4>
                        <p>
                          How you transform your business technolog consumer habits
                          industry dynamics change Find out from those leading the
                          charge How you
                        </p>
                      </div>
                    </div>
                    {/* col end*/}
                  </div>
                  {/* row end*/}
                  <div className="schedule-listing-btn">
                      <Link to='/Agenda' className="btn">
                        More Details
                      </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* container end*/}
      </section>
      {/* ts experience end*/}
    </>

  )
}

export default Schedule