import React from 'react'
import Header from '../Website/Header';
import Banner_KSA from '../Website/Whyksa/Banner_KSA';
import SignificanceKSA from '../Website/Whyksa/SignificanceKSA';
import Footer from '../Website/Footer';
import { useEffect } from 'react';
import { useRef } from 'react';

const Whyksa = () => {
  const topRef = useRef(null);

  useEffect(() => {
    topRef.current.scrollIntoView({ behavior: 'smooth' });
  }, []);

  return (
    <>
      <div ref={topRef}></div>
        <Header/>
        <Banner_KSA/>
        <SignificanceKSA/>
        <Footer/>
    </>
  )
}

export default Whyksa